import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { StateraUserClaimRepository } from './statera-user-claim.repository';

import * as models from '@statera/sdk/common';

@Injectable()
export class StateraUserClaimManager {
  private static readonly _lastTimeUserClaimsUpdatedHeaderKey = 'Last-Time-User-Claims-Updated';

  private _getStateraUserClaimsRequestInProcess = false;

  private readonly _stateraUserClaimRepository: StateraUserClaimRepository;

  constructor(stateraUserClaimRepository: StateraUserClaimRepository) {
    this._stateraUserClaimRepository = stateraUserClaimRepository;
  }

  checkIfStateraUserClaimsWasUpdated(httpResponse: HttpResponse<any>): void {
    const lastTimeUserClaimsUpdatedHeaderValue = httpResponse.headers.get(StateraUserClaimManager._lastTimeUserClaimsUpdatedHeaderKey);
    if (
      this._wasStateraUserClaimsUpdated(lastTimeUserClaimsUpdatedHeaderValue) &&
      !this._getStateraUserClaimsRequestInProcess
    ) {
      this._getStateraUserClaimsRequestInProcess = true;
      this.requestStateraUserClaims()
        .pipe(
          take(1),
        )
        .subscribe()
        .add(() => this._getStateraUserClaimsRequestInProcess = false);
    }
  }

  requestStateraUserClaims(): Observable<models.IStateraUserClaimsSimplifiedViewModel> {
    return this._stateraUserClaimRepository
      .requestStateraUserClaims()
      .pipe(
        tap(stateraUserClaims => this._stateraUserClaimRepository
          .setStoredStateraUserClaims(stateraUserClaims)
        ),
      );
  }

  getStoredStateraUserClaims(): Observable<models.IStateraUserClaimsSimplifiedViewModel> {
    return this._stateraUserClaimRepository.getStoredStateraUserClaims();
  }

  getStoredStateraUserClaimsSync(): models.IStateraUserClaimsSimplifiedViewModel {
    return this._stateraUserClaimRepository.getStoredStateraUserClaimsSync();
  }

  checkUserAccess(
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    companyId: number = null,
    portfolioId: number = null,
    buildingId: number = null,
    leaseId: number = null,
    floorPlanId: number = null,
    planProjectId: number = null
  ): boolean {
    const stateraUserClaims = this.getStoredStateraUserClaimsSync();
    return this.checkAccess(
      stateraUserClaims,
      stateraClaimType,
      stateraClaimValue,
      companyId,
      portfolioId,
      buildingId,
      leaseId,
      floorPlanId,
      planProjectId
    );
  }

  checkAccess(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    companyId?: number,
    portfolioId?: number,
    buildingId?: number,
    leaseId?: number,
    floorPlanId?: number,
    planProjectId?: number
  ): boolean {
    if (!stateraUserClaims) {
      return false;
    }

    if (!companyId && !portfolioId && !buildingId && !leaseId) {
      return false;
    }

    let hasAccess = true;

    if (companyId) {
      hasAccess = hasAccess && this.checkAccessToCompany(stateraUserClaims, stateraClaimType, stateraClaimValue, companyId);
    }

    if (portfolioId) {
      hasAccess = hasAccess && this.checkAccessToPortfolio(stateraUserClaims, stateraClaimType, stateraClaimValue, portfolioId);
    }

    if (buildingId) {
      hasAccess = hasAccess && this.checkAccessToBuilding(stateraUserClaims, stateraClaimType, stateraClaimValue, buildingId);
    }

    if (leaseId) {
      hasAccess = hasAccess && this.checkAccessToLease(stateraUserClaims, stateraClaimType, stateraClaimValue, leaseId);
    }

    if (floorPlanId) {
      hasAccess = hasAccess && this.checkAccessToFloorPlan(stateraUserClaims, stateraClaimType, stateraClaimValue, floorPlanId);
    }

    if (planProjectId) {
      hasAccess = hasAccess && this.checkAccessToPlanProject(stateraUserClaims, stateraClaimType, stateraClaimValue, planProjectId);
    }

    return hasAccess;
  }

  checkAccessToCompany(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    companyId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.CompanyGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.CompanyGroup)].includes(companyId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToPortfolio(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    portfolioId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.PortfolioGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.PortfolioGroup)].includes(portfolioId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToBuilding(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    buildingId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.BuildingGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.BuildingGroup)].includes(buildingId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToLease(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    leaseId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.LeaseGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.LeaseGroup)].includes(leaseId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToFloorPlan(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    floorPlanId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.FloorPlanGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.FloorPlanGroup)].includes(floorPlanId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  checkAccessToPlanProject(
    stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel,
    stateraClaimType: models.StateraClaimTypeAsEnum,
    stateraClaimValue: models.StateraClaimValueAsEnum,
    planProjectId: number,
  ): boolean {
    return (
      stateraUserClaims &&
      stateraUserClaims.groups &&
      stateraUserClaims.groups.some(x =>
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.PlanProjectGroup)] &&
        x.entityIds[this.getGroupName(models.StateraClaimsGroupKind.PlanProjectGroup)].includes(planProjectId) &&

        x.claims[this.getClaimTypeName(stateraClaimType)] &&
        x.claims[this.getClaimTypeName(stateraClaimType)].some(c => c >= stateraClaimValue),
      )
    );
  }

  private _wasStateraUserClaimsUpdated(lastTimeUserClaimsUpdatedHeaderValue: string): boolean {
    const stateraUserClaims = this.getStoredStateraUserClaimsSync();
    return (
      lastTimeUserClaimsUpdatedHeaderValue && stateraUserClaims &&
      stateraUserClaims.lastUpdatedTicksAsString !== lastTimeUserClaimsUpdatedHeaderValue
    );
  }

  getGroupName(group: models.StateraClaimsGroupKind): string {
    const groupName = models.StateraClaimsGroupKind[group];
    return `${groupName.charAt(0).toLowerCase()}${groupName.substring(1)}`;
  }

  getClaimTypeName(stateraClaimType: models.StateraClaimTypeAsEnum): string {
    const claimType = models.StateraClaimTypeAsEnum[stateraClaimType];
    return `${claimType.charAt(0).toLowerCase()}${claimType.substring(1)}`;
  }
}
