import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DxPopoverComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/auth';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AuthService } from '../../../../../auth/services/auth.service';
import { AlertService } from '../../../../../alert/services/alert.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import { SafeNumberPipe } from '../../../../../infrastructure/pipes/safe-number.pipe';
import { SafeCurrencyPipe } from '../../../../../infrastructure/pipes/safe-currency.pipe';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

interface TermOption {
  name: string;
  value: models.RealEstateTaxesCamExpensesTermType;
  disabled: boolean;
  insertData?: boolean;
}

enum PropertyManagementFeeDataSourceType {
  PsfValue = 0,
  BaseRent = 1,
  GrossReceipts = 2
}

@Component({
  selector: 'app-real-estate-taxes-cam-expenses',
  templateUrl: './real-estate-taxes-cam-expenses.component.html',
  styleUrls: ['./real-estate-taxes-cam-expenses.component.scss'],
})
export class RealEstateTaxesCamExpensesComponent extends BaseTermDirective<models.IRealEstateTaxesCamExpensesViewModel>
  implements OnInit, OnDestroy, OnChanges {
  options: Array<TermOption> = [
    {
      name: 'Per current lease',
      value: models.RealEstateTaxesCamExpensesTermType.PerCurrentLease,
      disabled: false,
    },
    {
      name: 'Tenant to pay its pro-rata share of Building\'s actual Operating expenses',
      value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfBuildingsActualOperatingExpenses,
      disabled: false,
    },
    {
      name: '',
      value: models.RealEstateTaxesCamExpensesTermType.PercentagePerYear,
      disabled: false,
      insertData: true
    },
    {
      name: 'Tenant shall retain the responsibility to provide all operating services and insure the property',
      value: models.RealEstateTaxesCamExpensesTermType.RetainingByTenant,
      disabled: false,
    },
    {
      name: 'Tenant to pay its pro-rata share of most recent expenses above a Base Year, where provided',
      value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfExpenses,
      disabled: true,
    },
    {
      name: 'All taxes & OpEx are included in the Gross Rental Rate',
      value: models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross,
      disabled: true,
    },
    {
      name: 'Custom',
      value: models.RealEstateTaxesCamExpensesTermType.Custom,
      disabled: false,
    },
  ];

  basis: Array<string> = ['Basis1', 'Basis2', 'Basis3'];

  cumulativeOptions: Array<any> = [
    {name: 'Cumulative Basis', value: models.RealEstateTaxesCamExpensesCumulativeType.Cumulative},
    {name: 'Non-Cumulative Basis', value: models.RealEstateTaxesCamExpensesCumulativeType.NonCumulative},
  ];

  managementFeeDataSource: Array<any> = [
    {name: 'PSF', value: PropertyManagementFeeDataSourceType.PsfValue},
    {name: '% Net Receipts', value: PropertyManagementFeeDataSourceType.BaseRent},
    {name: '% Gross Receipts', value: PropertyManagementFeeDataSourceType.GrossReceipts},
  ];

  selectedManagementFeeDataSource: PropertyManagementFeeDataSourceType;

  RealEstateTaxesCamExpensesTermType = models.RealEstateTaxesCamExpensesTermType;

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  RealEstateTaxesType: typeof models.RealEstateTaxesType = models.RealEstateTaxesType;
  OperatingExpensesType: typeof models.OperatingExpensesType = models.OperatingExpensesType;
  PropertyManagementFeeDataSourceType: typeof PropertyManagementFeeDataSourceType = PropertyManagementFeeDataSourceType;
  PropertyManagementFeeType: typeof models.PropertyManagementFeeType = models.PropertyManagementFeeType;
  PropertyManagementFeePercentageType: typeof  models.PropertyManagementFeePercentageType = models.PropertyManagementFeePercentageType;

  isEstimatedReTaxesStopType: boolean;
  isEstimatedOpExStopType: boolean;
  isOperatingExpensesBreakdownShown: boolean;

  protected alertService: AlertService;
  protected alertMessagesManager: AlertMessagesManager;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;
  protected leaseManager: LeaseManager;

  private readonly _projectAccessService: ProjectAccessService;
  private readonly _authService: AuthService;
  private readonly _safeCurrencyPipe: SafeCurrencyPipe;
  private readonly _safeNumberPipe: SafeNumberPipe;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
    safeCurrencyPipe: SafeCurrencyPipe,
    safeNumberPipe: SafeNumberPipe
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );
    this._authService = authService;
    this.leaseManager = leaseManager;
    this.termManager = termManager;
    this.termsPageService = termsPageService;
    this.alertService = alertService;
    this.alertMessagesManager = alertMessagesManager;
    this._projectAccessService = projectAccessService;
    this._safeCurrencyPipe = safeCurrencyPipe;
    this._safeNumberPipe = safeNumberPipe;
    this._destroy$ = new Subject<void>();

    this.getDevExpressPSFPerUnitMetricsFormat = this.getDevExpressPSFPerUnitMetricsFormat.bind(this);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.leaseTerm = <models.IRealEstateTaxesCamExpensesViewModel>{...(this.leaseTerm || {})};

    this._initialize();

    this.accordionOpened$
      .pipe(
        tap(() => {
          this._initialize();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }

    if (changes.leaseTerm) {
      const {leaseTerm} = changes;

      if (
        leaseTerm.isFirstChange() ||
        (
          (
            leaseTerm.previousValue &&
            leaseTerm.currentValue
          ) &&
          leaseTerm.previousValue.estimatedReTaxesType !== leaseTerm.currentValue.estimatedReTaxesType
        )
      ) {
        this.isEstimatedReTaxesStopType = this.leaseTerm && this.leaseTerm.estimatedReTaxesType === models.RealEstateTaxesType.Stop;
      }

      if (
        leaseTerm.isFirstChange() ||
        (
          (
            leaseTerm.previousValue &&
            leaseTerm.currentValue
          ) &&
          leaseTerm.previousValue.estimatedOpExType !== leaseTerm.currentValue.estimatedOpExType
        )
      ) {
        this.isEstimatedOpExStopType = this.leaseTerm && this.leaseTerm.estimatedOpExType === models.OperatingExpensesType.Stop;
      }
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleAcceptButtonClick(): void {
    if (
      this.lease &&
      this.lease.baseRentalRateTerm &&
      this.lease.baseRentalRateTerm.baseRentalRateType !== models.BaseRentalRateType.Gross &&
      this.lease.realEstateTaxesCamExpensesTerm &&
      typeof this.lease.realEstateTaxesCamExpensesTerm.estimatedReTaxes !== 'number' &&
      typeof this.lease.realEstateTaxesCamExpensesTerm.estimatedOpEx !== 'number' &&
      this.projectAccessService.getTurnRole(this.lease) === Role.Landlord
    ) {
      this.isRejectFormVisible = true;
      this.isRejectFormVisibleChange.emit(this.isRejectFormVisible);

      this.acceptClicked.emit();
    } else {
      super.handleAcceptButtonClick();
    }
  }

  handleInsuranceChange(value: number): void {
    if (!this.leaseTerm) {
      return;
    }

    this.leaseTerm = {
      ...this.leaseTerm,
      insurance: value,
    };
  }

  handlePropertyManagementFeeChange(value: number): void {
    if (!this.leaseTerm) {
      return;
    }

    if (this.leaseTerm.propertyManagementFeeType === models.PropertyManagementFeeType.PsfValue) {
      this.leaseTerm = {
        ...this.leaseTerm,
        propertyManagementFeePsf: value,
      };

      return;
    }

    if (this.leaseTerm.propertyManagementFeeType === models.PropertyManagementFeeType.Percentage) {
      this.leaseTerm = {
        ...this.leaseTerm,
        propertyManagementFeePercentage: value,
      };

      return;
    }
  }

  handleManagementOptionsChange({ value }: { value: PropertyManagementFeeDataSourceType }): void {
    if (!this.leaseTerm) {
      return;
    }

    let feeType: models.PropertyManagementFeeType;
    let percentageType: models.PropertyManagementFeePercentageType;

    switch (value) {
      case PropertyManagementFeeDataSourceType.PsfValue:
        feeType = models.PropertyManagementFeeType.PsfValue;
        percentageType = models.PropertyManagementFeePercentageType.None;
        break;

      case PropertyManagementFeeDataSourceType.BaseRent:
        feeType = models.PropertyManagementFeeType.Percentage;
        percentageType = models.PropertyManagementFeePercentageType.BaseRent;
        break;

      case PropertyManagementFeeDataSourceType.GrossReceipts:
        feeType = models.PropertyManagementFeeType.Percentage;
        percentageType = models.PropertyManagementFeePercentageType.GrossReceipts;
        break;
    }

    this.leaseTerm = {
      ...this.leaseTerm,
      propertyManagementFeeType: feeType,
      propertyManagementFeePercentageType: percentageType,
    };
  }

  showPopover(popoverComponent: DxPopoverComponent): void {
    if (!popoverComponent || !popoverComponent.instance) {
      return;
    }

    const popoverInstance = popoverComponent.instance;
    popoverInstance.option('closeOnTargetScroll', () => {
      popoverInstance.repaint();
      return true;
    });

    popoverComponent.visible = true;
  }

  saveLeaseTerm($event) {

    this.clearErrors();

    const {realEstateTaxesCamExpensesTermType} = this.leaseTerm;
    if (realEstateTaxesCamExpensesTermType == null) {
      this.addError('You should choose appropriate options.');
      return;
    }

    const isBaseYear = (
      this.lease?.baseRentalRateTerm &&
      (
        this.lease?.baseRentalRateTerm?.baseRentalRateType === models.BaseRentalRateType.BaseYear ||
        (
          this.lease?.baseRentalRateTerm?.leaseTermOptions?.options?.length &&
          this.lease?.baseRentalRateTerm?.leaseTermOptions?.options[0]?.baseRentalRateType === models.BaseRentalRateType.BaseYear
        )
      )
    );

    if (
      isBaseYear &&
      this._projectAccessService.getTurnRole(this.lease) === Role.Landlord &&
      !this.isEstimatedReTaxesStopType &&
      !this.isEstimatedOpExStopType
    ) {
      this.addError('A stop must be selected for Modified Gross lease structure');
      return;
    }

    const templateItem = this.project.projectState.renewalProjectTemplateItemType;
    if (!this.project || !this.project.projectState) {
      return;
    }

    let termStatus = models.TermStatus.Rejected;
    if (this.acceptButtonClicked) {
      termStatus = models.TermStatus.Accepted;
    } else if (templateItem === models.RenewalProjectTemplateItemType.SendRfp) {
      termStatus = models.TermStatus.Ready;
    } else if (templateItem === models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord) {
      termStatus = models.TermStatus.Ready;
    }

    this.leaseTerm.isTermAcceptance = this.acceptButtonClicked;

    super.saveLeaseTerm(this.leaseTerm, termStatus);

    $event.preventDefault();
  }

  shouldShowRealEstateTaxesAndCamExpenses(): boolean {
    if (
      !this.lease ||
      !this.lease.term ||
      !this.lease.baseRentalRateTerm ||
      !this.lease.realEstateTaxesCamExpensesTerm
    ) {
      return false;
    }

    const {term, baseRentalRateTerm} = this.lease;

    if (
      term.termType === models.TermTypeEnum.MultiplyOptions &&
      baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      baseRentalRateTerm.leaseTermOptions &&
      baseRentalRateTerm.leaseTermOptions.options &&
      baseRentalRateTerm.leaseTermOptions.options.length
    ) {
      const selectedOption = baseRentalRateTerm.leaseTermOptions.selectedLeaseTerm;
      if (selectedOption && selectedOption.baseRentalRateType === models.BaseRentalRateType.Gross) {
        return false;
      }

      const firstOption = baseRentalRateTerm.leaseTermOptions.options[0];
      if (firstOption && firstOption.baseRentalRateType === models.BaseRentalRateType.Gross) {
        return false;
      }
    }

    if (baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.Gross) {
      return false;
    }

    return (
      (this.acceptButtonClicked || this.rejectButtonClicked || this.leaseTerm.termStatus === models.TermStatus.Draft) &&
      this._projectAccessService.getTurnRole(this.lease) === Role.Landlord &&
        !this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.EstablishCriteria,
          this.project,
          this.lease
        ) &&
      this.projectAccessService.checkAccessToRenewalProject(
        this.project?.projectState?.renewalProjectTemplateItemType,
        this.project,
        this.lease
      )
    );
  }

  isUnsolicitedOfferStage(): boolean {
    if (!this.project) {
      return false;
    }

    return this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord,
      this.project,
      this.lease
    );
  }

  getRealEstateTaxesBreakdownTable(): models.ILeaseTermCustomTableViewModel {
    return this.termManager.getRealEstateTaxesBreakdownTable(this.leaseTerm);
  }

  getBaseRentalRateUnitMetrics(): string {
    if (!this.lease) {
      return null;
    }

    return this.termManager.getBaseRentalRateUnitMetricsDisplayValue(this.lease.baseRentalRateTerm);
  }

  isBaseRentalRateModifiedGross(): boolean {
    if (!this.lease || !this.lease.term || !this.lease.baseRentalRateTerm) {
      return false;
    }

    const {term, baseRentalRateTerm} = this.lease;

    if
    (
      term.termType === models.TermTypeEnum.MultiplyOptions &&
      baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      baseRentalRateTerm.leaseTermOptions &&
      baseRentalRateTerm.leaseTermOptions.options &&
      baseRentalRateTerm.leaseTermOptions.options.length
    ) {
      if (baseRentalRateTerm.leaseTermOptions.selectedLeaseTerm) {
        const selectedOption = baseRentalRateTerm.leaseTermOptions.selectedLeaseTerm;
        return selectedOption.baseRentalRateType === models.BaseRentalRateType.BaseYear;
      }

      const firstOption = baseRentalRateTerm.leaseTermOptions.options[0];
      return firstOption.baseRentalRateType === models.BaseRentalRateType.BaseYear;
    }

    return baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.BaseYear;
  }

  handleEstimatedReTaxesTypeChange(): void {
    if (this.isEstimatedReTaxesStopType) {
      this.leaseTerm.estimatedReTaxesType = models.RealEstateTaxesType.Stop;
      return;
    }

    this.leaseTerm.estimatedReTaxesType = models.RealEstateTaxesType.Estimate;
  }

  handleEstimatedOpExpTypeChange(): void {
    if (this.isEstimatedOpExStopType) {
      this.leaseTerm.estimatedOpExType = models.OperatingExpensesType.Stop;
      return;
    }

    this.leaseTerm.estimatedOpExType = models.OperatingExpensesType.Estimate;
  }

  showEstimatesTooltip() {
    const leaseTeam = this.leaseManager
      .getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);
    return leaseTeam !== models.LeaseTeam.LandlordTeam;
  }

  getPropertyManagementFee(): string {

    const { term, baseRentalRateTerm } = this.lease;

    if
    (
      term.termType === models.TermTypeEnum.MultiplyOptions &&
      baseRentalRateTerm.baseRentalRateType === models.BaseRentalRateType.MultiplyOptions &&
      baseRentalRateTerm.leaseTermOptions &&
      baseRentalRateTerm.leaseTermOptions.options &&
      baseRentalRateTerm.leaseTermOptions.options.length &&
      this.leaseTerm.propertyManagementFeeType === models.PropertyManagementFeeType.Percentage
    ) {
      if (!baseRentalRateTerm.leaseTermOptions.selectedLeaseTerm) {
        return `Property Management Fee is ${this.leaseTerm.propertyManagementFeePercentage}% of [Gross/Net] receipts.`;
      }
    }

    const propertyManagementFee = this.termManager.getPropertyManagementFee(this.leaseTerm, this.lease);

    return `${this._safeCurrencyPipe.transform(propertyManagementFee)} ${this.getBaseRentalRateUnitMetrics()}`;
  }

  getTotalValue(includeReTaxes: boolean = false): number {
    return this.termManager.getTotalEstimatedRealEstateTaxesValue(this.leaseTerm, this.lease, includeReTaxes);
  }

  private _initialize(): void {
    this._prepareOptions();
  }

  private _prepareOptions() {
    const baseRentalRate = this.lease.baseRentalRateTerm;

    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name:
            'Tenant to pay its pro-rata share of Building\'s actual Operating expenses. ' +
            'Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfBuildingsActualOperatingExpenses,
          disabled: false,
        },
        {
          name: 'Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.PercentagePerYear,
          disabled: false,
          insertData: true
        },
        {
          name: 'Tenant shall retain the responsibility to provide all operating services and insure the property. ' +
            'Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.RetainingByTenant,
          disabled: false,
        },
        {
          name: 'Tenant to pay its pro-rata share of most recent expenses above a Base Year, where provided',
          value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfExpenses,
          disabled: true,
        },
        {
          name: 'All taxes & OpEx are included in the Gross Rental Rate',
          value: models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross,
          disabled: true,
        },
        {
          name: 'Custom. Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.Custom,
          disabled: false,
        },
      ];
    } else {
      this.options = [
        {
          name: 'Per current lease. Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.PerCurrentLease,
          disabled: false,
        },
        {
          name: 'Tenant to pay its pro-rata share of Building\'s actual Operating expenses. ' +
            'Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfBuildingsActualOperatingExpenses,
          disabled: false,
        },
        {
          name: 'Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.PercentagePerYear,
          disabled: false,
          insertData: true
        },
        {
          name: 'Tenant shall retain the responsibility to provide all operating services and insure the property. ' +
            'Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.RetainingByTenant,
          disabled: false,
        },
        {
          name: 'Tenant to pay its pro-rata share of most recent expenses above a Base Year, where provided',
          value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfExpenses,
          disabled: true,
        },
        {
          name: 'All taxes & OpEx are included in the Gross Rental Rate',
          value: models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross,
          disabled: true,
        },
        {
          name: 'Custom. Landlord to provide most current OpEx estimates, as needed.',
          value: models.RealEstateTaxesCamExpensesTermType.Custom,
          disabled: false,
        },
      ];
    }

    this.options.forEach((option) => {
      option.disabled = false;

      if (option.value === models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfExpenses) {
        option.disabled = !this.isBaseRentalRateModifiedGross();
      }
    });

    if (baseRentalRate) {
      if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Gross) {

        // disable options

        const retainingByTenantOption = this.options
          .find(x => x.value === models.RealEstateTaxesCamExpensesTermType.RetainingByTenant);

        if (retainingByTenantOption) {
          retainingByTenantOption.disabled = true;
        }

        const percentagePerYearOption = this.options
          .find(x => x.value === models.RealEstateTaxesCamExpensesTermType.PercentagePerYear);

        if (percentagePerYearOption) {
          percentagePerYearOption.disabled = true;
        }
      } else {
        const reOpExIncludedInGrossOption = this.options
          .find(x => x.value === models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross);

        if (reOpExIncludedInGrossOption) {
          reOpExIncludedInGrossOption.disabled = true;
        }
      }

      if (baseRentalRate.baseRentalRateType === models.BaseRentalRateType.BaseYear) {
        const reOpExIncludedInGross = this.options
          .find(x => x.value === models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross);

        if (reOpExIncludedInGross) {
          reOpExIncludedInGross.disabled = true;
        }
      }

      this._disablePerCurrentLeaseOption();
    }

    if (this.leaseTerm.propertyManagementFeeType === models.PropertyManagementFeeType.PsfValue) {
      this.selectedManagementFeeDataSource = PropertyManagementFeeDataSourceType.PsfValue;
    } else if (this.leaseTerm.propertyManagementFeeType === models.PropertyManagementFeeType.Percentage) {
      if (this.leaseTerm.propertyManagementFeePercentageType === models.PropertyManagementFeePercentageType.BaseRent) {
        this.selectedManagementFeeDataSource = PropertyManagementFeeDataSourceType.BaseRent;
      } else if (this.leaseTerm.propertyManagementFeePercentageType === models.PropertyManagementFeePercentageType.GrossReceipts) {
        this.selectedManagementFeeDataSource = PropertyManagementFeeDataSourceType.GrossReceipts;
      }
    } else {
      this.leaseTerm.propertyManagementFeeType = models.PropertyManagementFeeType.PsfValue;
      this.selectedManagementFeeDataSource = PropertyManagementFeeDataSourceType.PsfValue;
    }
  }

  private _disablePerCurrentLeaseOption() {
    const baseRentalRate = this.lease.baseRentalRateTerm;

    if (
      baseRentalRate.abstract &&
      baseRentalRate.baseRentalRateType &&
      models.BaseRentalRateType.None < baseRentalRate.baseRentalRateType &&
      baseRentalRate.abstract.baseRentalRateType &&
      baseRentalRate.baseRentalRateType !== baseRentalRate.abstract.baseRentalRateType &&
      (
        baseRentalRate.baseRentalRateType !== models.BaseRentalRateType.MultiplyOptions &&
        baseRentalRate.baseRentalRateType !== models.BaseRentalRateType.RequestMultiplyOptions
      )
    ) {

      const perCurrentLeaseOption = this.options
        .find(x => x.value === models.RealEstateTaxesCamExpensesTermType.PerCurrentLease);

      if (perCurrentLeaseOption) {
        perCurrentLeaseOption.disabled = true;
      }
    }
  }

  validateZeroValues(event): boolean {
    if (!event) {
      return true;
    }

    return event.value !== 0;
  }

  getDevExpressPSFPerUnitMetricsFormat<T>(value: T): string {
    const format = `$ %v ${this.getBaseRentalRateUnitMetrics()}`;

    if (value === null || value === undefined) {
      return format.replace('%v', '0.00');
    }

    return format.replace('%v', this._safeNumberPipe.transform(value, '1.2-2'));
  }

  handleRealEstateTaxesCamExpensesTermTypeChange(termType: models.RealEstateTaxesCamExpensesTermType): void {
    if (!this.leaseTerm) {
      return;
    }

    const { PercentagePerYear } = models.RealEstateTaxesCamExpensesTermType;
    if (!this.leaseTerm.realEstateTaxesCamExpensesCumulativeType && termType === PercentagePerYear) {
      this.leaseTerm.realEstateTaxesCamExpensesCumulativeType = models.RealEstateTaxesCamExpensesCumulativeType.Cumulative;
      return;
    }

    this.leaseTerm.realEstateTaxesCamExpensesCumulativeType = null;
  }
}
