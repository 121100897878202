import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssetsTypeRepository } from './assets-type.repository';

import * as models from './assets-type.model';

@Injectable()
export class AssetsTypeManager {
  private readonly _assetsTypeRepository: AssetsTypeRepository;

  constructor(assetsTypeRepository: AssetsTypeRepository) {
    this._assetsTypeRepository = assetsTypeRepository;
  }

  getAssetsTypes(): Observable<Array<models.AssetsType>> {
    return this._assetsTypeRepository
      .getAssetsTypes();
  }
}
