import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { AddressRepository } from './address.repository';
import { AddressManager } from './address.manager';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AddressRepository,
    AddressManager,
  ],
})
export class AddressModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AddressModule> {
    return {
      ngModule: AddressModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AddressRepository,
        AddressManager,
      ],
    };
  }
}
