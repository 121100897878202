import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { take, tap } from 'rxjs/operators';

import { AppService } from '../../shared/services/app.service';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

export interface Properties {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private readonly _loggerService: LoggerService;

  private _monitor: ApplicationInsights;

  constructor(appService: AppService, loggerService: LoggerService) {
    this._loggerService = loggerService;

    appService
      .getConfiguration()
      .pipe(
        take(1),
        tap(config => {
          if (!config || !config.appInsightsSettings) {
            return;
          }

          const { instrumentationKey } = config.appInsightsSettings;
          if (!instrumentationKey) {
            return;
          }

          this._monitor = new ApplicationInsights({
            config: {
              instrumentationKey: instrumentationKey,
              enableAutoRouteTracking: true,
            },
          });

          this._monitor.loadAppInsights();
        }),
      )
      .subscribe();
  }

  trackEvent(name: string, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackEvent({name}, properties));
  }

  trackException(exception: Error, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackException({exception}, properties));
  }

  trackTrace(message: string, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackTrace({message}, properties));
  }

  trackMetric(name: string, average: number, properties?: Properties): void {
    this._runIfConfigured(monitor => monitor.trackMetric({name, average}, properties));
  }

  startTrackPage(name: string): void {
    this._runIfConfigured(monitor => {
      monitor.startTrackPage(name);
      this._loggerService.debug(LoggerTopic.General, `Start of page tracking: ${name}`);
    });
  }

  stopTrackPage(name: string): void {
    this._runIfConfigured(monitor => {
      monitor.stopTrackPage(name);
      this._loggerService.debug(LoggerTopic.General, `End of page tracking: ${name}`);
    });
  }

  private _runIfConfigured(callback: (monitor: ApplicationInsights) => void): void {
    if (!this._monitor) {
      return;
    }

    callback(this._monitor);
  }
}
