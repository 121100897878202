import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { ConstructionTypeRepository } from './construction-type.repository';
import { ConstructionTypeManager } from './construction-type.manager';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    ConstructionTypeRepository,
    ConstructionTypeManager,
  ],
})
export class ConstructionTypeModule {
  static forChild(configuration: Configuration): ModuleWithProviders<ConstructionTypeModule> {
    return {
      ngModule: ConstructionTypeModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        ConstructionTypeRepository,
        ConstructionTypeManager,
      ],
    };
  }
}
