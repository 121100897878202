import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { AuthManager, StartupInfo } from '@statera/sdk/auth';
import { ProjectManager } from '@statera/sdk/project';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

import { TourClientVersionCompatibility } from '../../models/tour-client-version-compatibility.model';
import { TourProcessState } from '../../models/tour-process-state.model';
import { TimeSlotEntry, TimeSlotOption } from '../../models/tour-time-slot.model';

const TIME_SLOTS = [
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
];

const moment = TourClientVersionCompatibility.moment;

@Component({
  selector: 'app-tour-schedule-dialog',
  templateUrl: './tour-schedule-dialog.component.html',
})
export class TourScheduleDialogComponent implements OnInit, OnDestroy {
  readonly TourIconsAssetPath = TourClientVersionCompatibility.TourIconsAssetPath;
  readonly timezone = 'America/Chicago';

  @Input() landlordCompany: models.ICompanyViewModel;
  @Input() tenantCompany: models.ICompanyViewModel;
  @Input() buildingUnit: models.IBuildingUnitViewModel;
  @Input() building: models.IBuildingViewModel;
  @Input() leaseTeam: models.LeaseTeam;
  @Input() projectId: number;
  @Input() counterPartCompany: models.ICompanyViewModel;
  @Input() counterPartUser: models.IProfileViewModel;
  @Input() tour?: models.ITourViewModel;
  @Input() tour$?: Subject<models.ITourViewModel>;
  @Input() shouldRejectTourRequest: boolean;
  @Input() shouldCreateTourRequest: boolean;

  TourProcessState = TourProcessState;

  private readonly _projectManager: ProjectManager;
  private readonly _authManager: AuthManager;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;

  timeSlots = TIME_SLOTS;

  state = TourProcessState.Initial;
  availableTimeSlots: Array<string>;
  selectedTimeSlots: Array<TimeSlotEntry> = [];
  timeSlotOptions: Array<TimeSlotOption> = [];
  approvedTimeSlotOption?: TimeSlotOption;

  selectedDate: moment.Moment;
  selectedDateTitle: string;

  startupInfo: Observable<StartupInfo>;

  private _cachedBuildingThumbnailUrl = '';

  constructor(
    projectManager: ProjectManager,
    authManager: AuthManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
  ) {
    this._projectManager = projectManager;
    this._authManager = authManager;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this.startupInfo = this._authManager.getStartupInfo();

    this.shouldCreateTourRequest ??= true;
    this.selectedDate = moment().tz(this.timezone);
    this.selectedDateTitle = '';

    this._init();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getDateTitle(date: moment.Moment): string {
    return date.format('dddd, MMMM D');
  }

  getSlotDurationExpression(slot: string): string {
    return [
      slot,
      moment(slot, 'LT')
        .add(30, 'minutes')
        .format('LT')
    ].join(' — ');
  }

  getBuildingPictureUrl(): string {
    if (!this.building.attachments) {
      return 'assets/img/nofoto.png';
    }

    if (!this._cachedBuildingThumbnailUrl) {
      const firstPicture = this.building.attachments
        .filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.Picture)
        .sort((a, b) => a.sortOrder - b.sortOrder)[0];

      this._cachedBuildingThumbnailUrl = firstPicture?.file?.thumbnailUrl ?? 'assets/img/nofoto.png';
    }

    return this._cachedBuildingThumbnailUrl;
  }

  getReferencedDates(): Array<moment.Moment> | null {
    return this.selectedTimeSlots?.map((ts) => moment.tz(ts.date, 'utc').tz(this.timezone));
  }

  getApprovedTimeSlots(): Array<TimeSlotEntry> {
    if (!this.approvedTimeSlotOption) {
      return this.selectedTimeSlots;
    }

    return [{
      date: this.approvedTimeSlotOption.date,
      timeSlots: [this.approvedTimeSlotOption.timeSlot],
    }];
  }

  getAvailableTimeSlots(): Array<string> {
    const result = [];

    const availableTimeSlots = this.selectedTimeSlots
      .filter((ts) => (
        this.selectedDate.get('year') === ts.date.get('year') &&
        this.selectedDate.get('month') === ts.date.get('month') &&
        this.selectedDate.get('date') === ts.date.get('date')
      ))
      .map((ts) => (
        ts.timeSlots
      ));

    for (let x = 0; x < availableTimeSlots.length; x++) {
      for (let i = 0; i < availableTimeSlots[x].length; i++) {
        result.push(availableTimeSlots[x][i]);
      }
    }

    return result;
  }

  isOwnTimeSlotPicked(): boolean {
    if (this.state === TourProcessState.ReplyAccept) {
      return !!this.approvedTimeSlotOption;
    }

    return true;
  }

  isTooManyTimeSlotsSelected(): boolean {
    return this._getSelectedTimeSlotsCount() > 5;
  }

  isSlotActive(slot: string): boolean {
    if (this.state === TourProcessState.ReplyAccept) {
      if (!this.approvedTimeSlotOption) {
        return false;
      }

      return (
        this.selectedDate.get('year') === this.approvedTimeSlotOption.date.get('year') &&
        this.selectedDate.get('month') === this.approvedTimeSlotOption.date.get('month') &&
        this.selectedDate.get('date') === this.approvedTimeSlotOption.date.get('date') &&
        slot === this.approvedTimeSlotOption.timeSlot
      );
    }

    const entry = this.selectedTimeSlots
      .find((ts) => (
        ts.date.isSame(this.selectedDate)
      ));
    if (!entry) {
      return false;
    }

    return entry.timeSlots.includes(slot);
  }

  onDateSelected(date: moment.Moment): void {
    this.selectedDate = date;
    this.selectedDateTitle = this.getDateTitle(this.selectedDate);

    if (this.state === TourProcessState.ReplyAccept) {
      this.availableTimeSlots = this.getAvailableTimeSlots();
    }
  }

  onTimeSlotClick(timeSlot: string) {
    let entry: TimeSlotEntry;
    const entryIndex = this.selectedTimeSlots
      .findIndex((ts) => (
        ts.date.isSame(this.selectedDate)
      ));

    if (entryIndex === -1) {
      entry = <TimeSlotEntry>{
        date: this.selectedDate,
        timeSlots: [timeSlot],
      };

      this.selectedTimeSlots.push(entry);
      this.selectedTimeSlots
        .sort((a, b) =>
          a.date.unix() - b.date.unix()
        );

      return;
    } else {
      entry = this.selectedTimeSlots[entryIndex];
    }

    const timeSlotIndex = entry.timeSlots.indexOf(timeSlot);
    if (timeSlotIndex !== -1) {
      entry.timeSlots.splice(timeSlotIndex, 1);

      if (entry.timeSlots.length === 0) {
        this.selectedTimeSlots.splice(entryIndex, 1);
      }

      return;
    }

    entry.timeSlots.push(timeSlot);

    this.selectedTimeSlots
      .sort((a, b) =>
        a.date.unix() - b.date.unix()
      );

    entry.timeSlots.sort((a, b) => TIME_SLOTS.indexOf(a) - TIME_SLOTS.indexOf(b));
  }

  onRemoveSlotClick(entry: TimeSlotEntry, slot: string): void {
    if (this.state === TourProcessState.ReplyAccept) {
      this.approvedTimeSlotOption = null;

      return;
    }

    const index = entry.timeSlots.indexOf(slot);
    entry.timeSlots.splice(index, 1);

    if (entry.timeSlots.length === 0) {
      const entryIndex = this.selectedTimeSlots.indexOf(entry);
      this.selectedTimeSlots.splice(entryIndex, 1);
    }
  }

  onConfirmTimeSlotsButtonClick() {
    if (this.state === TourProcessState.Initial) {
      const action = this.tour
        ? this._projectManager
          .updateProjectTour({
            ...this.tour,
            dateTimeSlots: this.selectedTimeSlots.map(timeSlot => ({
              ...timeSlot,
              date: timeSlot.date.toISOString()
            })),
            confirmedDateTimeSlot: null,
          })
        : this._projectManager
          .createProjectTour(
            this.projectId,
            this.selectedTimeSlots.map(timeSlot => ({
              ...timeSlot,
              date: timeSlot.date.toISOString()
            })),
            this.shouldCreateTourRequest,
          );

      action
        .pipe(
          tap((result) => {
            this.tour$?.next(result);
            this._dialogRefService.outputData = {
              isTourChanged: true,
            };
            this.close();
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();

      return;
    }

    if (this.state === TourProcessState.ReplyReject || this.state === TourProcessState.Edit) {
      this._projectManager
        .updateProjectTour({
          ...this.tour,
          dateTimeSlots: this.selectedTimeSlots.map(timeSlot => ({
            ...timeSlot,
            date: timeSlot.date.toISOString()
          })),
          confirmedDateTimeSlot: null,
        })
        .pipe(
          tap((result) => {
            this.tour$?.next(result);
            this._dialogRefService.outputData = {
              isTourChanged: true,
            };
            this.close();
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  onCancelTimeSlotsButtonClick() {
    if (
      (this.tour.isApprovedByTenant && this.tour.isApprovedByLandlord) ||
      (this.tour.isApprovedByTenant && this.leaseTeam === models.LeaseTeam.TenantTeam) ||
      (this.tour.isApprovedByLandlord && this.leaseTeam === models.LeaseTeam.LandlordTeam)
    ) {
      this.close();

      return;
    }

    this.shouldRejectTourRequest = false;

    this.state = TourProcessState.Initial;
    this.selectedTimeSlots = [];
    this.approvedTimeSlotOption = null;

    this._init();
  }

  onApproveTourButtonClick() {
    if (!this.approvedTimeSlotOption) {
      return;
    }

    this._projectManager
      .updateProjectTour({
        ...this.tour,
        confirmedDateTimeSlot: {
          ...this.approvedTimeSlotOption,
          date: this.approvedTimeSlotOption.date.toISOString(),
        },
      })
      .pipe(
        tap((tour) => {
          this.tour$?.next(tour);

          this._dialogRefService.outputData = {
            isTourChanged: true,
          };

          this.close();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  onReplyRejectButtonClick() {
    const alertReference = this._alertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmTourAlternateDateText(),
      });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this.state = TourProcessState.ReplyReject;
          this.selectedTimeSlots = [];
          this.approvedTimeSlotOption = null;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  setApprovedTimeSlot(slot: string): void {
    this.approvedTimeSlotOption = <TimeSlotOption>{
      date: this.selectedDate,
      timeSlot: slot,
    };
  }

  close() {
    this._dialogRefService.hide();
  }

  cancelTour() {
    const alertReference = this._alertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmCancelTourText(),
      });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._projectManager.cancelTour(this.tour)),
        tap(() => {
          this.tour$?.next(null);

          this._dialogRefService.outputData = {
            isTourChanged: true,
          };

          this.close();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _getTimeSlotOptions(): Array<TimeSlotOption> {
    const options: Array<TimeSlotOption> = [];

    this.selectedTimeSlots
      .forEach((ts) => (
        ts.timeSlots
          .forEach((slot) => (
            options.push({
              date: ts.date,
              timeSlot: slot,
            })
          ))
      ));

    return options;
  }

  private _getSelectedTimeSlotsCount(): number {
    if (!this.selectedTimeSlots) {
      return 0;
    }

    let count = 0;

    this.selectedTimeSlots
      .forEach((ts) => (
        ts.timeSlots.forEach(() => count++)
      ));

    return count;
  }

  private _init(): void {
    if (!this.tour) {
      this.state = TourProcessState.Initial;

      return;
    }

    const needsApproval = (
      (this.leaseTeam === models.LeaseTeam.TenantTeam && !this.tour.isApprovedByTenant) ||
      (this.leaseTeam === models.LeaseTeam.LandlordTeam && !this.tour.isApprovedByLandlord)
    );

    if (this.tour.status === models.TourStatus.WaitingForApproval || this.tour.status === models.TourStatus.Scheduled) {
      this.selectedTimeSlots = this.tour
        .dateTimeSlots
        .map((ts) => {
          return {
            date: moment.tz(ts.date, this.timezone),
            timeSlots: [...ts.timeSlots],
          };
        });

      if (this.shouldRejectTourRequest) {
        this.state = TourProcessState.ReplyReject;
        this.selectedTimeSlots = [];
        this.approvedTimeSlotOption = null;

        return;
      }

      this.timeSlotOptions = this._getTimeSlotOptions();

      if (needsApproval) {
        this.state = TourProcessState.ReplyAccept;

        this.availableTimeSlots = this.getAvailableTimeSlots();
      } else {
        this.state = (
          this.tour.status === models.TourStatus.Scheduled ?
            TourProcessState.Edit :
            TourProcessState.ReplyReject
        );
      }

      return;
    }

    if (needsApproval) {
      this.state = TourProcessState.ReplyAccept;

      this.availableTimeSlots = this.getAvailableTimeSlots();

      return;
    }

    this.state = TourProcessState.Initial;
  }
}
