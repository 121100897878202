<div class="mt-2 term-history">
  <div class="row"
       *ngIf="(records?.length || lastValue || acceptedValue) && !hideTitle">
    <h6>Please see response(s) below:</h6>
  </div>

  <ng-container
    *ngFor="let record of records | slice:0: showAllRecords ? records.length : slicedRecords; let recordNumber = index">
    <ng-container>
      <div class="b-diff"
           [class.b-diff-landlord]="record.leaseTeam === LeaseTeam.LandlordTeam"
           [class.b-diff-tenant]="record.leaseTeam === LeaseTeam.TenantTeam">
        <div class="b-diff-inner">
          <ng-container *ngIf="record.shouldShowAcceptanceMark">
            <div class="acceptance-icon"
                 #acceptanceMarkElement>
            </div>

            <dx-tooltip position="top"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        [width]="250"
                        [target]="acceptanceMarkElement">
              <div class="acceptanceMarkElement-tooltip">
                The Landlord has agreed with provided options
              </div>
            </dx-tooltip>
          </ng-container>

          <div #historyDateValue
               [class.b-diff-tenant-line-through]="record.leaseTeam === LeaseTeam.TenantTeam"
               [class.b-diff-landlord-line-through]="record.leaseTeam === LeaseTeam.LandlordTeam">
            <ng-container *ngFor="let termSettingDiff of record?.termSettingDiffs">
              <span
                [attr.class]="record.isHighlighted ? getDiffClassName(termSettingDiff) : null">{{ termSettingDiff?.Value }}</span>
            </ng-container>
            <dx-tooltip *ngIf="!showAllRecords"
                        class="small-tooltip"
                        position="top"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        [target]="historyDateValue">
              {{ record.leaseTeam === LeaseTeam.LandlordTeam ? 'Sent by Landlord ' + (record?.timestamp | timeAgo) : 'Sent by Tenant ' + (record?.timestamp | timeAgo) }}
            </dx-tooltip>
          </div>

          <div class="b-diff-modified"
               *ngIf="record?.isModifiedValue">
            [modified]
          </div>
        </div>
        <ng-container *ngIf="showAllRecords">
          <div class="b-diff-timestamp" #recordTimestamp>{{ record.timestamp | timeAgo }}</div>
          <dx-tooltip class="small-tooltip" position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                      [target]="recordTimestamp">
            {{ record?.timestamp | date : 'd MMM y, h.mm a' }}
          </dx-tooltip>
        </ng-container>
      </div>

    </ng-container>

    <div class="b-diff"
         *ngIf="record.hasLeaseTermCustomTable">
      <div class="b-diff-inner">
        <app-lease-term-custom-table [leaseTermCustomTable]="record.leaseTermCustomTable"
                                     [isLineThrough]="true" [leaseTeam]="record.leaseTeam">
        </app-lease-term-custom-table>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="leaseTermConfiguration">
    <ng-container *ngIf="acceptedValue">
      <div class="b-diff b-diff-final accepted-value"
           #popoverContainerElement>
        <div class="b-diff-inner align-items-center justify-content-start">
          <span inlineSVG="assets/img/sign-icons.svg#green-circle-icon" class="circle-icon"></span>
          <div #acceptedDateValue>
            <ng-container *ngFor="let termSettingDiff of acceptedValue.termSettingDiffs">
              <span>{{ termSettingDiff?.Value }}</span>
            </ng-container>
            <dx-tooltip *ngIf="!showAllRecords"
                        class="small-tooltip" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend"
                        [target]="acceptedDateValue">
              Accepted by both parties
            </dx-tooltip>
          </div>
          <app-lease-term-custom-table-popover *ngIf="acceptedValue.hasPreviewLeaseTermTable"
                                               [popoverContainer]="popoverContainerElement"
                                               [leaseTermCustomTable]="acceptedValue.previewLeaseTermTable"
                                               [role]="getRoleByLeaseTeam(acceptedValue.leaseTeam)" [isAccepted]="true">
          </app-lease-term-custom-table-popover>

          <div class="rent-schedule-preview-multiple-options"
               *ngIf="isBaseRentalRate && lease?.baseRentalRateTerm?.hasMultiplyOptions">
            <ng-container *ngFor="let multipleOptionTable of lease?.baseRentalRateTerm?.multipleOptionsScheduleTables">
              <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                   [leaseTermCustomTable]="multipleOptionTable"
                                                   [role]="getRoleByLeaseTeam(acceptedValue.leaseTeam)">
              </app-lease-term-custom-table-popover>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="showAllRecords">
          <div class="d-flex align-items-center b-diff-timestamp-accepted-date">
            <span>proposed by {{ acceptedValue.leaseTeam === LeaseTeam.TenantTeam ? 'tenant' : 'landlord' }} <span
              class="b-diff-timestamp-underline">{{ acceptedValue?.createdOn | timeAgo }}</span></span>
            <span class="grey-circle">.</span>
            <span>accepted by {{ acceptedValue.leaseTeam === LeaseTeam.TenantTeam ? 'landlord' : 'tenant' }} <span
              class="b-diff-timestamp-underline">{{ acceptedValue?.timestamp | timeAgo }}</span></span>
          </div>
        </ng-container>
      </div>

      <div class="b-diff"
           *ngIf="acceptedValue.hasLeaseTermCustomTable">
        <div class="b-diff-inner">
          <app-lease-term-custom-table [leaseTermCustomTable]="acceptedValue.leaseTermCustomTable"
                                       [isLineThrough]="false" [isAccepted]="true"
                                       [leaseTeam]="acceptedValue.leaseTeam">
          </app-lease-term-custom-table>
        </div>
      </div>

      <div class="b-diff"
           *ngIf="getAdditionalValueTable() as additionalTable">
        <div class="b-diff-inner">
          <app-lease-term-custom-table [leaseTermCustomTable]="additionalTable"
                                       [isLineThrough]="false" [isAccepted]="true">
          </app-lease-term-custom-table>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lastValue">
      <div class="b-diff last-value"
           #popoverContainerElement>
        <div class="b-diff-inner">
          <ng-container *ngIf="lastValue.shouldShowAcceptanceMark">
            <div class="acceptance-icon"
                 #acceptanceMarkElement>
            </div>

            <dx-tooltip position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                        [width]="250" [target]="acceptanceMarkElement">
              The Landlord has agreed with provided options
            </dx-tooltip>
          </ng-container>

          <div #historyValue
               [class.b-diff-landlord]="lastValue.leaseTeam === LeaseTeam.LandlordTeam"
               [class.b-diff-tenant]="lastValue.leaseTeam === LeaseTeam.TenantTeam">
            <ng-container *ngFor="let termSettingDiff of lastValue.termSettingDiffs">
              <span
                [attr.class]="lastValue.isHighlighted ? getDiffClassName(termSettingDiff) : null">{{ termSettingDiff?.Value }}</span>
            </ng-container>
            <dx-tooltip *ngIf="!showAllRecords"
                        class="small-tooltip" position="top"
                        showEvent="dxhoverstart" hideEvent="dxhoverend"
                        [target]="historyValue">
              {{ lastValue.leaseTeam === LeaseTeam.LandlordTeam ? 'Sent by Landlord ' + (lastValue?.timestamp | timeAgo) : 'Sent by Tenant ' + (lastValue?.timestamp | timeAgo) }}
            </dx-tooltip>
          </div>

          <div class="b-diff-modified"
               *ngIf="lastValue?.isModifiedValue">
            [modified]
          </div>

          <app-lease-term-custom-table-popover *ngIf="lastValue.hasPreviewLeaseTermTable"
                                               [popoverContainer]="popoverContainerElement"
                                               [leaseTermCustomTable]="lastValue.previewLeaseTermTable"
                                               [role]="getRoleByLeaseTeam(lastValue.leaseTeam)">
          </app-lease-term-custom-table-popover>
          <div class="rent-schedule-preview-multiple-options"
               *ngIf="isBaseRentalRate && lease?.baseRentalRateTerm?.hasMultiplyOptions">
            <ng-container *ngFor="let multipleOptionTable of lease?.baseRentalRateTerm?.multipleOptionsScheduleTables">
              <app-lease-term-custom-table-popover [popoverContainer]="popoverContainerElement"
                                                   [leaseTermCustomTable]="multipleOptionTable"
                                                   [role]="getRoleByLeaseTeam(lastValue.leaseTeam)">
              </app-lease-term-custom-table-popover>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="showAllRecords">
          <div class="b-diff-timestamp" #lastValueTampstamp>{{ lastValue.timestamp | timeAgo }}</div>
          <dx-tooltip class="small-tooltip" position="top" showEvent="dxhoverstart" hideEvent="dxhoverend"
                      [target]="lastValueTampstamp">
            {{ lastValue?.timestamp | date : 'd MMM y, h.mm a' }}
          </dx-tooltip>
        </ng-container>

        <ng-container *ngIf="isTenantLeaseTeam() &&
                           isMultipleOptionsTerm() &&
                           shouldShowMultipleOptionsCustomTable() &&
                           canGetMultipleOptionsCustomTable()">
          <div id="compareOptions" class="row">
            <label #optionLabel>Compare Options</label>
            <app-lease-term-custom-table-popover [popoverContainer]="optionLabel"
                                                 [leaseTermCustomTable]="getMultipleOptionsCustomTable()">
            </app-lease-term-custom-table-popover>
          </div>
        </ng-container>
      </div>

      <div class="b-diff"
           *ngIf="lastValue.hasLeaseTermCustomTable">
        <div class="b-diff-inner">
          <app-lease-term-custom-table [leaseTermCustomTable]="lastValue.leaseTermCustomTable" [isLineThrough]="false"
                                       [isAccepted]="false"
                                       [leaseTeam]="lastValue.leaseTeam">
          </app-lease-term-custom-table>
        </div>
      </div>

      <div class="b-diff"
           *ngIf="getAdditionalValueTable() as additionalTable">
        <div class="b-diff-inner">
          <app-lease-term-custom-table [leaseTermCustomTable]="additionalTable" [isLineThrough]="false" [isAccepted]="false">
          </app-lease-term-custom-table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="showAllRecords">
  <span>
    <span class="dot dot-landlord"></span>
    <span>Landlord</span>
  </span>
  <span>
    <span class="dot dot-tenant"></span>
    <span>Tenant</span>
  </span>
</div>

<button class="b-diff-toggle-history"
        (click)="showAllRecords = !showAllRecords">
  {{ showAllRecords ? "hide term history" : "show all term history" }}
</button>

<div class="mt-2" *ngIf="demoRentalRateTerm">
  <div class="b-diff b-diff-tenant b-diff-tenant-line-through">
    <span class="term-diff-added">
      Please provide an aggressive, market sensitive rate.
      Please indicate the precise structure of the rental rate for(net, gross or full service).
      If the structure is not net, please clearly describe the additional rent inclusions.
    </span>
  </div>

  <div class="b-diff b-diff-landlord">
    <span class="term-diff-added">
      $333.00 PSF GROSS
    </span>
  </div>
</div>
