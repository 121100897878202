<div class="b-lease-terms-header">
  {{termConfiguration.description}}

  <span class="badge badge-pill badge-primary"
        *ngIf="hasNewTermBadge()">
    New Term
  </span>
</div>

<div class="b-lease-terms-controls">
  <button class="b-btn b-btn-icon b-btn--small btn"
          (click)="tooltipVisible[termConfiguration.termName] = true"
          #tooltipElement>
    <span class="icon-info"
          inlineSVG="assets/img/signs-icons.svg#icon-tooltip">
    </span>
  </button>

  <div class="btn-group"
       dropdown
       container="body">
    <a href class="b-lease-terms-controls-dropdown {{getTermStatus(lease).toLowerCase()}}"
       [class.demo-status]="termConfiguration.termName ==='RentalRate'"
       (click)="clickOnStatus($event)"
       dropdownToggle>
      {{getTermStatus(lease)}}
    </a>
    <!--classes - ready, draft, reject-->
  </div>
  <!--btn-group-->

</div>
<!--b-lease-terms-controls-->

<dx-tooltip position="top"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            [class.demo-tooltip]="termConfiguration.termName ==='RentalRate'"
            [target]="tooltipElement"
            [width]="250"
            [(visible)]="tooltipVisible[termConfiguration.termName]">
  <div>
    {{termConfiguration.tooltip}}
  </div>
</dx-tooltip>

<dx-tooltip class="demo-tooltip"
            position="top"
            [target]="tooltipElement"
            [width]="250"
            [closeOnOutsideClick]="false"
            #demoTooltip>
  <div>
    {{termConfiguration.tooltip}}
  </div>
</dx-tooltip>
