<div class="container container--wide">
  <div class="w-mobile-timeline">
    <div class="b-step">
      <div class="b-step-media">{{ activeStep?.id }}</div>
      <div class="b-step-text">{{ activeStep?.name }}</div>
    </div>
    <dx-accordion class="b-collapse"
                  [collapsible]="true"
                  [animationDuration]="300"
                  [focusStateEnabled]="false"
                  [selectedIndex]="-1">
      <ng-container>
        <dxi-item [title]="'See all steps'">
          <app-timeline-vertical [project]="project"></app-timeline-vertical>
        </dxi-item>
      </ng-container>
    </dx-accordion>
  </div>

  <app-timeline *ngIf="project && isShowTimeline()"
                [project]="project"
                [showTooltips]="!!authService?.startupInfo?.introsShown">
  </app-timeline>

  <div class="colabo-columns">
    <section id="channels" class="w-col-channels col-channels"
             [ngClass]="{ collapsed: channelListCollapsed }">
      <div class="col-channels-header collapse-button-mob">
        <button class="collapse-button"
                (click)="closeChannel()">
          <span class="icon-backward"></span>
        </button>
      </div>

      <button class="collapse-button collapse-button-desk"
              (click)="channelListCollapsed = !channelListCollapsed">
        <span class="icon-backward"></span>
      </button>

      <dx-scroll-view>
        <div class="chat-tree-wrapper">
          <app-chat-tree class="col-channels-tree" routeSegmentsCount="2" subpath="main"
                         [lease]="lease"
                         (channelChanged)="closeChannel()">
          </app-chat-tree>
        </div>
      </dx-scroll-view>
    </section>

    <section #termListContainer class="w-col-lease col-lease" (window:resize)="onResize()">
      <dx-tab-panel *ngIf="isMobile" #tabPanel
                    [dataSource]="contentTabs"
                    [selectedIndex]="0"
                    [swipeEnabled]="true"
                    [focusStateEnabled]="false"
                    [deferRendering]="false" class="colabo-tab-panel">
        <!--        <div *dxTemplate="let company of 'templ1'">-->
        <!--          <ng-container *ngTemplateOutlet="tabTemplate1;context:ctx"></ng-container>-->
        <!--        </div>-->
        <div *dxTemplate="let company of 'templ2'" class="w-col-chat col-chat">
          <ng-container *ngTemplateOutlet="tabTemplate2"></ng-container>
        </div>
      </dx-tab-panel>

      <ng-container *ngIf="!isMobile">
        <ng-container *ngIf="leaseTeam; else termListLoadingTemplate">
          <app-term-list (cancelNegotiationByTenant)="onCancelNegotiationByTenant()"
                         (cancelNegotiationByLandlord)="onCancelNegotiationByLandlord()"
                         [lease]="lease"
                         [project]="project"
                         [leaseTeam]="leaseTeam"
                         #termList>
          </app-term-list>
        </ng-container>
        <ng-template #termListLoadingTemplate>
          <div class="h-100 p-5 d-flex align-items-center justify-content-center">
            <app-spinner>
            </app-spinner>
          </div>
        </ng-template>
      </ng-container>
    </section>

    <section *ngIf="!isMobile" id="colabo-chat" class="w-col-chat col-chat">
      <div class="demo-alerts">
        <ng-container *ngTemplateOutlet="tabTemplate2"></ng-container>
      </div>
    </section>
  </div>
  <!--colabo-columns-->
</div>
<!--container-->

<!-- Templates -->

<ng-template #tabTemplate2>
  <button class="controls-back"
          (click)="closeChat()">
    <span class="icon-back"></span>
  </button>

  <div class="col-chat-lease-team" *ngIf="isShowLeaseTeam()">
    <div class="team-column">
      <div class="team-column-heading">Landlord Team</div>

      <dx-scroll-view>
        <div class="team-column-items"
             [ngClass]="{
             'several-columns': leaseTeam.landlordTeam.length > 2,
             'two-columns': leaseTeam.landlordTeam.length > leaseTeam.tenantTeam.length && leaseTeam.landlordTeam.length > 2
             }">
          <div class="company-info">
            <img class="company-info-logo" alt="company logo" [src]="getLandlordCompanyLogoUrl()"/>
            <div class="company-info-title">{{ getLandlordCompanyName() }}</div>
          </div>

          <div class="user-info"
               *ngFor="let user of leaseTeam.landlordTeam">
            <img class="user-info-avatar"
                 alt="user avatar"
                 [src]="user.user.avatar?.url ?? 'assets/img/avatar.png'"/>
            <div class="user-info-title"
                 #landlordTeamMemberElement>
              {{ user.user.displayName }}
              <dx-tooltip class="small-tooltip" position="bottom"
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          contentTemplate="content"
                          [target]="landlordTeamMemberElement">
                <p class="mb-0">
                  {{ LeaseTeamUserRole[user.role] || 'Unknown' }}
                </p>
              </dx-tooltip>
            </div>


          </div>
        </div>
      </dx-scroll-view>
    </div>

    <div class="team-column">
      <div class="team-column-heading">Tenant Team</div>

      <dx-scroll-view>
        <div class="team-column-items"
             [ngClass]="{
             'several-columns': leaseTeam.tenantTeam.length > 2,
             'two-columns': leaseTeam.landlordTeam.length <= leaseTeam.tenantTeam.length && leaseTeam.tenantTeam.length > 2
             }">
          <div class="company-info">
            <img class="company-info-logo"
                 alt="company logo"
                 [src]="getTenantCompanyLogoUrl()"/>
            <div class="company-info-title">{{ getTenantCompanyName() }}</div>
          </div>

          <div class="user-info"
               *ngFor="let user of leaseTeam.tenantTeam">
            <img class="user-info-avatar"
                 [src]="user.user.avatar?.url ?? 'assets/img/avatar.png'"/>
            <div class="user-info-title"
                 #tenantTeamMemberElement>
              {{ user.user.displayName }}

              <dx-tooltip class="small-tooltip"
                          position="bottom"
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          contentTemplate="content"
                          [target]="tenantTeamMemberElement">
                <p class="mb-0">
                  {{ LeaseTeamUserRole[user.role] || 'Unknown' }}
                </p>
              </dx-tooltip>
            </div>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </div>

  <dx-tabs class="col-chat-tabs"
           itemTemplate="tabTemplate"
           [items]="tabs"
           [(selectedIndex)]="selectedTabIndex">
    <div *dxTemplate="let itemData of 'tabTemplate'; let itemIndex = index">
      <span class="tab-title">{{ itemData.text }}</span>
    </div>
  </dx-tabs>

  <section *ngIf="selectedTabIndex === 0" class="col-chat-chat">
    <app-chat-view [leaseId]="lease?.id"></app-chat-view>
  </section>

  <section *ngIf="selectedTabIndex === 1" class="col-chat-chat">
    <app-chat-view-team [leaseId]="lease?.id"></app-chat-view-team>
  </section>

  <section *ngIf="selectedTabIndex === 2" class="col-chat-chat">
    <app-alert-list [lease]="lease"
                    [project]="project"
                    [height]="tabHeight"
                    #alertList>
    </app-alert-list>
  </section>
</ng-template>

<app-colabo-demo (skipped)="onSkippedDemo()" #colaboDemo></app-colabo-demo>
