import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonTools } from '@statera/sdk/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap, tap } from 'rxjs/operators';

import { ApplicationStore } from '../states/application/application.store';

import * as models from '../../infrastructure/models/generated';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  private readonly _httpClient: HttpClient;
  private readonly _applicationStore: ApplicationStore;
  private readonly _router: Router;

  private readonly _configuration: BehaviorSubject<models.IConfigurationViewModel>;

  static get isMobile(): boolean {
    return window.innerWidth <= 992;
  }

  desktopWidth100 = false;
  private _routes100 = ['colabo'];

  constructor(router: Router, httpClient: HttpClient, applicationStore: ApplicationStore) {
    this._httpClient = httpClient;
    this._applicationStore = applicationStore;
    this._router = router;

    this._configuration = new BehaviorSubject<models.IConfigurationViewModel>(null);

    this._router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.checkRoute();
        }
      });

    window.onresize = () => {
      this.checkRoute();
    };
  }

  checkRoute() {
    if (AppService.isMobile) {
      this.desktopWidth100 = false;
    } else {
      // tslint:disable-next-line:forin
      for (const i in this._routes100) {
        if (this._router.url.indexOf(this._routes100[i]) >= 0) {
          this.desktopWidth100 = true;
          break;
        }
        this.desktopWidth100 = false;
      }
    }
  }

  getConfiguration(): Observable<models.IConfigurationViewModel> {
    return this._configuration
      .pipe(
        distinctUntilChanged((prev, curr) => !CommonTools.isEqual(prev, curr)),
        switchMap(config => {
          if (config) {
            return of(config);
          }

          return this._httpClient
            .get<models.IConfigurationViewModel>(`${environment.webApiUrl}/app/Configuration`)
            .pipe(
              tap(configuration => this._applicationStore.updateConfiguration(configuration))
            );
        }),
      );
  }
}
