import * as models from '@statera/sdk/common';

export type PortfolioInvitation = models.IPortfolioInvitationViewModel;

export type PortfolioInvitationInfo = models.IPortfolioInvitationInfoViewModel;

export type PortfolioInvitationUser = models.IInvitationUserViewModel;

export type PortfolioInvitationKind = models.InvitationKind;
export const PortfolioInvitationKind = models.InvitationKind;

export type PortfolioInvitationUserAcceptanceStatus = models.InvitationUserAcceptanceStatus;
export const PortfolioInvitationUserAcceptanceStatus = models.InvitationUserAcceptanceStatus;
