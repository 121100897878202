import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './address.model';

@Injectable()
export class AddressRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getAddressProperties(): Observable<models.AddressProperties> {
    return this._httpClient
      .get<models.AddressProperties>(
        `${this._configuration.apiEndpoint}/app/AddressProperties`,
      );
  }

  getAddressByGeopoint(latitude: number, longitude: number): Observable<string> {
    let params = new HttpParams();

    params = params.set('latitude', latitude.toString());
    params = params.set('longitude', longitude.toString());

    return this._httpClient
      .get<string>(
        `${this._configuration.apiEndpoint}/address/GetAddressByGeopoint`,
        {
          params,
        },
      );
  }
}
