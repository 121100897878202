import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConstructionTypeRepository } from './construction-type.repository';

import * as models from './construction-type.model';

@Injectable()
export class ConstructionTypeManager {
  private readonly _constructionTypeRepository: ConstructionTypeRepository;

  constructor(constructionTypeRepository: ConstructionTypeRepository) {
    this._constructionTypeRepository = constructionTypeRepository;
  }

  getConstructionTypes(): Observable<Array<models.ConstructionType>> {
    return this._constructionTypeRepository
      .getConstructionTypes();
  }
}
