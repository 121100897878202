import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { Configuration } from '@statera/sdk/common';

import { AssetsTypeRepository } from './assets-type.repository';
import { AssetsTypeManager } from './assets-type.manager';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AssetsTypeRepository,
    AssetsTypeManager,
  ],
})
export class AssetsTypeModule {
  static forChild(configuration: Configuration): ModuleWithProviders<AssetsTypeModule> {
    return {
      ngModule: AssetsTypeModule,
      providers: [
        {
          provide: Configuration,
          useValue: configuration,
        },
        AssetsTypeRepository,
        AssetsTypeManager,
      ],
    };
  }
}
