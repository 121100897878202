import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '@statera/sdk/common';

import * as models from './assets-type.model';

@Injectable()
export class AssetsTypeRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getAssetsTypes(): Observable<Array<models.AssetsType>> {
    return this._httpClient
      .get<Array<models.AssetsType>>(
        `${this._configuration.apiEndpoint}/app/AssetsTypes`,
      );
  }
}
