<div class="b-card my-dashboard"
     *ngIf="(userRole | async) as userRole"
     #unit>
  <a [routerLink]="['/landlord/buildings']" class="portfolio-link">
    <span inlineSVG="assets/img/arrow-line.svg"></span>
    back to portfolio
  </a>

  <div class="my-flex-block">
    <div class="background"
         *ngIf="buildingPictures">
      <app-building-attachments [attachments]="buildingPictures">
      </app-building-attachments>
    </div>

    <div class="background placeholder"
         style="background-image: url(assets/img/landlord/dashboard/image-placeholder.svg)"
         *ngIf="!buildingPictures">
    </div>

    <div class="block-content">
      <h1 class="b-header fancy">
        <span>{{ building.address.displayString }}</span>
        <!-- NOTE: Remove d-none from the class list to make the gear button visible -->
        <span class="b-header-controls d-none">
          <button id="btnBuildingSettings" class="b-btn-icon" (click)="tooltipVisible = true">
            <span class="icon-settings green-icon"></span>
          </button>
        </span>
      </h1>

      <dx-tooltip target="#btnBuildingSettings"
                  position="left"
                  width="150px"
                  [showEvent]="'click'"
                  [(visible)]="tooltipVisible">
        <div *dxTemplate="let data = data; of: 'content'" class="d-flex flex-column tooltip-menu">
          <a [routerLink]="['../../edit-building/' + id]" class="nav-link text-uppercase text-13 header-text"
             (click)="tooltipVisible = false">
            Edit
          </a>
        </div>
      </dx-tooltip>

      <div class="main-info">
        <div class="info-block square-feet">
          <span inlineSVG="assets/img/sign-icons.svg#ruler-icon"></span>
          <div>
            <h2 class="b-list-header">building size</h2>
            <p class="b-list-content">
              <span class="b-list-content-text"
                    *ngIf="building.totalBuildingSize">
                {{ building.totalBuildingSize | safeNumber }} rentable square feet
              </span>
            </p>
          </div>
        </div>

        <div class="info-block">
          <span inlineSVG="assets/img/sign-icons.svg#settings-icon"></span>
          <div>
            <h2 class="b-list-header">building type</h2>

            <p class="b-list-content">
              <span class="b-list-content-text text-capitalize">
                {{ getBuildingTypeName(building?.buildingType) }}
              </span>
            </p>
          </div>
        </div>

        <div class="info-block">
          <span inlineSVG="assets/img/sign-icons.svg#calendar-icon"></span>
          <div>
            <h2 class="b-list-header">Year Built</h2>

            <p class="b-list-content">
              <span class="b-list-content-text">
                {{ year ? year : 'N/A' }}
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- TODO: This is temporarily disabled for all role types except Landlord -->
      <div style="margin-top: 26px;"
           *ngIf="building.id && userRole === Role.Landlord">
        <ng-container *ngIf="!building.teamMembers?.length; else cardLeasingTeamTemplate">
          <button type="button"
                  class="assign-leasing-team-btn"
                  (click)="handleBuildingAssignLeasingTeamButtonClick(building)">
            <i inlineSVG='assets/icons/inlineSVG/lanlordBuildings.svg#plus'></i>
            assign a leasing team
          </button>
        </ng-container>
        <ng-template #cardLeasingTeamTemplate>
          <div>
            <div class="leasing-team__title">
              Leasing team:
            </div>

            <div class="leasing-team">
              <ng-container *ngFor="let user of building.teamMembers">
                <div class="leasing-team-member">
                  <img [src]="user.avatar?.url || 'assets/img/no-image.svg'"
                       [alt]="user.fullName"
                       #leasingTeamMemberTooltip/>
                  <dx-tooltip position="bottom"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              [target]="leasingTeamMemberTooltip">
                    <div>
                      <span>{{ user.firstName }}  {{ user.lastName }}</span>
                    </div>

                  </dx-tooltip>

                </div>
              </ng-container>
              <button type="button"
                      class="leasing-team-edit-btn"
                      (click)="handleBuildingManageLeasingTeamButtonClick(building)"
                      #buildingLeasingTeamEditButton>
                <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"></i>
              </button>
              <dx-tooltip position="bottom"
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          [target]="buildingLeasingTeamEditButton">
                <span>Edit</span>
              </dx-tooltip>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="tabs-block">
    <dx-tabs itemTemplate="tabItem"
             [items]="tabs" [(selectedIndex)]="selectedTabIndex"
             (onSelectionChanged)="selectTab()" (onItemClick)="toggleTooltip($event)">
      <div *dxTemplate="let itemData of 'tabItem'; let i = index" id="{{'tab' + i}}">
        <span class="dx-tab-text">{{ itemData.text }}</span>
      </div>
    </dx-tabs>
  </div>

  <dx-data-grid class="tenants-grid column-input"
                allowHtmlText="True"
                wordWrapEnabled="true"
                width="100%"
                [(visible)]="dealsGridVisible"
                [dataSource]="deals"
                [remoteOperations]="true"
                [showBorders]="false"
                [columnAutoWidth]="false"
                *ngIf="deals?.length > 0">

    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="false">
    </dxo-search-panel>

    <dxo-paging [pageSize]="4"></dxo-paging>

    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>

    <!-- Start: Type -->
    <dxi-column alignment="left"
                caption="Type"
                allowSearch="true"
                cellTemplate="typeCellTemplate"
                [encodeHtml]="false">
    </dxi-column>

    <div class="colabo-badges-wrapper"
         *dxTemplate="let item of 'typeCellTemplate'">
      <app-colabo-badges [project]="item?.data?.projects?.[0]" [lease]="item?.data?.lease">
      </app-colabo-badges>
    </div>
    <!-- End: Type -->

    <!-- Start: Tenant / Suite -->
    <dxi-column dataField="fullTenantName"
                alignment="left"
                caption="Tenant / Suite"
                allowSearch="true"
                cellTemplate="nameCellTemplate"
                headerCellTemplate="nameHeaderTemplate"
                [encodeHtml]="false">
    </dxi-column>
    <div *dxTemplate="let item of 'nameHeaderTemplate'">
      <span>Tenant Unit</span>
    </div>

    <div *dxTemplate="let item of 'nameCellTemplate'">
      <a [routerLink]="['../../person-desktop/' + item?.data?.leaseId]"
         *ngIf="item?.data?.leaseId">
        {{ item?.data?.tenantCompanyName }}
        <br/>
        {{ item?.data?.unitName }}
      </a>
    </div>
    <!-- End: Tenant / Suite -->
    <!-- Start: Stage -->
    <dxi-column dataType="number"
                alignment="left"
                caption="Stage"
                cellTemplate="stateCellTemplate"
                width="200px">
    </dxi-column>

    <div *dxTemplate="let item of 'stateCellTemplate'" class="d-flex flex-column">
      <ng-container *ngIf="item?.data?.projectState || item?.data?.parentProjectState; else noState">
        <span>{{ getStatusName(item?.data) }}</span>
        <div class="stage-line-wrapper">
          <div class="stage-line"
               [class.active-stage]="getStatusNumber(item?.data) > i"
               *ngFor="let stage of [].constructor(maxStageCount); let i = index">
          </div>
        </div>
      </ng-container>
      <ng-template #noState>
        <span class="stage-number">0</span>
        <span>Abstract</span>
      </ng-template>
    </div>
    <!-- End: Stage -->
    <!-- Start: Size -->
    <dxi-column dataField="tenantSquareFootageTerm"
                caption="Size"
                alignment="left"
                [calculateCellValue]="getCurrentTenantSquareFootage"
                [calculateSortValue]="getCurrentTenantSquareFootageNumber"
                [encodeHtml]="false">
    </dxi-column>
    <!-- End: Size -->
    <!-- Start: Base Rental Rate -->
    <dxi-column dataField="baseRentalRateTerm"
                caption=" Current Rental Rate"
                dataType="number"
                alignment="left"
                headerCellTemplate="currentHeaderRentalRateTemplate"
                cellTemplate="currentRentalRateTemplate"
                [editorOptions]="{ placeholder: 'Enter Rate'}"
                [calculateSortValue]="getCurrentRentalRateNumber">
    </dxi-column>

    <div class="starting-base-rental-rate"
         *dxTemplate="let item of 'currentHeaderRentalRateTemplate'">
      <div>
        <div>Starting Base</div>
        <div> Rental Rate</div>
      </div>
      <div class="rental-rate-badge-tooltip">
        <div class="rental-rate-badge" id="rental-rate-badge">
          MG
        </div>

        <dx-tooltip target="#rental-rate-badge"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top">
          <div>All Deals are on a Modified Gross structure</div>
        </dx-tooltip>
      </div>
    </div>

    <div class="cell-base-rate"
         *dxTemplate="let item of 'currentRentalRateTemplate'">
      <div class="d-flex align-items-center">
        <a class="current-rental-rate-coast" role="button">
          {{ getCurrentRentalRateNumber(item?.data) | safeCurrency }} {{ getUnitMetricsDisplayValue(item?.data) }}
        </a>

        <div class="cell-base-rate-tooltip">
          <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#eye-tooltip-12"
             (click)="showPopover(rentPreviewSchedulePopover)"
             #popoverContainerElement>
          </i>
        </div>

      </div>
      <dx-popover class="rent-schedule-preview-popover"
                  [target]="popoverContainerElement"
                  [position]="{of: popoverContainerElement, my: 'right top', at: 'right bottom', collision: 'none'}"
                  #rentPreviewSchedulePopover>
        <div *dxTemplate="let data of 'content'">
          <div class="tooltip-menu">

            <div class="tooltip-header">

              <div class="tooltip-header__title">
                <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#eye-icon-26"></i>
                <span>Rent Schedule Preview</span>
              </div>

              <button type="button"
                      (click)="rentPreviewSchedulePopover.visible = false;">
                <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#cross-close"></i>
              </button>

            </div>

            <div class="recent-schedule-preview">
              <div #baseRentalScheduleWrapperElement>
                <ng-container *ngIf="getDealBaseRentalSchedule(item?.data) as baseRentalSchedule; else elseBlock">
                  <table>
                    <tr>
                      <th>Start</th>
                      <th>End</th>
                      <th>Base Rent {{ getUnitMetricsDisplayValue(item?.data) }}</th>
                      <th>Monthly Rent</th>
                      <th>Annual Rent</th>
                    </tr>
                    <tr *ngFor="let row of baseRentalSchedule.rows"
                        [class.current-row]="isCurrentBaseRentalRateSchedulePeriod(row)">
                      <td>
                        <div class="d-flex flex-column align-items-start">
                          <div class="current"
                               *ngIf="isCurrentBaseRentalRateSchedulePeriod(row)">
                            Current
                          </div>
                          <span>{{ row.start | date:'MM/dd/yyyy' }}</span>
                        </div>
                      </td>
                      <td>{{ row.end | date:'MM/dd/yyyy' }}</td>
                      <td>{{ row.baseRent | currency }}</td>
                      <td>
                        <ng-container *ngIf="row.monthlyRent; else noRent">
                          {{ row.monthlyRent | currency }}
                        </ng-container>
                        <ng-template #noRent>
                          -
                        </ng-template>
                      </td>
                      <td>
                        <ng-container *ngIf="row.annualRent; else noAnnualRent">
                          {{ row.annualRent | currency }}
                        </ng-container>
                        <ng-template #noAnnualRent>
                          -
                        </ng-template>
                      </td>
                    </tr>
                  </table>
                </ng-container>

                <ng-template #elseBlock>
                  <div class="comment">
                    No Base Rental Schedule available
                  </div>
                </ng-template>
              </div>

            </div>
          </div>
        </div>
      </dx-popover>
      <ng-template #noBaseRent>
        N/A
      </ng-template>
    </div>
    <!-- End: Base Rental Rate -->
    <!-- Start: Lease Expiration -->
    <dxi-column dataField="expiration"
                caption="Lease Expiration"
                alignment="left"
                cellTemplate="expirationTpl">
    </dxi-column>

    <div class="lease-expiration-column"
         *dxTemplate="let item of 'expirationTpl'">
      <ng-container *ngIf="item?.value; else noExpiration">

        {{ item?.value | safeDate: 'MM/dd/yyyy' }}

        <div class="lease-expiration-icon">
          <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#calendar"
             #startDateInfo></i>
        </div>

        <dx-tooltip class="active-hover"
                    position="bottom"
                    showEvent="dxhoverstart"
                    [hideEvent]="{name: 'dxhoverend', delay: 1000}"
                    [target]="startDateInfo">
          <div class="date-tooltip">
            <p class="option-notice-title">Option Notice Dates:</p>
            <div class="d-flex flex-column date-tooltip-wrapper">
              <div>
                <p class="date-tooltip-title">Renewal</p>
                <p class="date-tooltip-text">
                  <ng-container *ngIf="item?.data?.renewalNoticeDate as renewalDate; else noRenewalDate">
                    {{ renewalDate | safeDate: 'MM/dd/yyyy' }}
                  </ng-container>
                  <ng-template #noRenewalDate>
                    N/A
                  </ng-template>
                </p>
                <a class="date-tooltip-icon"
                   [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                   [queryParams]="{target: 'noticeDates'}">
                  <span inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></span>
                </a>
              </div>
              <div>
                <p class="date-tooltip-title">Expansion</p>
                <p class="date-tooltip-text">
                  <ng-container
                    *ngIf="item?.data?.expansionNoticeDate as expansionDate; else noExpansionDate">
                    {{ expansionDate | safeDate: 'MM/dd/yyyy' }}
                  </ng-container>
                  <ng-template #noExpansionDate>
                    N/A
                  </ng-template>
                </p>
                <a class="date-tooltip-icon"
                   [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                   [queryParams]="{target: 'noticeDates'}">
                  <span inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></span>
                </a>
              </div>
              <div>
                <p class="date-tooltip-title">Termination</p>
                <p class="date-tooltip-text">
                  <ng-container
                    *ngIf="item?.data?.terminationNoticeDate as terminationDate; else noTerminationDate">
                    {{ terminationDate | safeDate: 'MM/dd/yyyy' }}
                  </ng-container>
                  <ng-template #noTerminationDate>
                    N/A
                  </ng-template>
                </p>
                <a class="date-tooltip-icon"
                   [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                   [queryParams]="{target: 'noticeDates'}">
                  <span inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></span>
                </a>
              </div>
            </div>
          </div>
        </dx-tooltip>
      </ng-container>
      <ng-template #noExpiration>
        N/A
      </ng-template>
    </div>
    <!-- End: Lease Expiration -->
    <!-- Start: Team -->
    <dxi-column caption="Team"
                cellTemplate="avatarCellTemplate"
                [allowFiltering]="false"
                [allowHeaderFiltering]="false"
                [allowGrouping]="false"
                [allowSearch]="false"
                [allowSorting]="false">
    </dxi-column>

    <div *dxTemplate="let item of 'avatarCellTemplate'">
      <app-team-members-avatars [projects]="projects"
                                [lease]="item?.data?.lease"
                                [leaseId]=" item?.data?.leaseId"
                                [teamMembers]="item?.data?.teamMembers">
      </app-team-members-avatars>
    </div>

    <dxi-column cellTemplate="actionsCellTemplate"
                caption="&nbsp;"
                alignment="right"
                [allowFiltering]="false"
                [allowHeaderFiltering]="false"
                [allowGrouping]="false"
                [allowSearch]="true"
                [allowSorting]="false">
    </dxi-column>

    <div class="action-buttons-group"
         *dxTemplate="let item of 'actionsCellTemplate'">

      <ng-container *appCheckAccess="StateraClaimType.Team_Member;
                    stateraClaimValue: StateraClaimValue.Read;
                    leaseId: item?.data?.leaseId;">

        <button [title]="item.data.projectNotes ? 'Edit a Comment': 'Add a Comment'"
                (click)="addProjectNotes($event, item)">

          <i [inlineSVG]=" item.data.projectNotes ? 'assets/icons/inlineSVG/building-desktop.svg#editComment' : 'assets/icons/inlineSVG/building-desktop.svg#addAcomment'"></i>

        </button>

        <button title="Manage Team" (click)="manageTeam($event, item)">
          <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#manageTeam"></i>
        </button>
        <button title="Project Schedule" (click)="goToProjectSchedule(item)">
          <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#projectSchedule"></i>
        </button>
        <button title="Lease Analysis" (click)="goToLeaseAnalysis(item)">
          <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#leaseAnalysis"></i>
        </button>
        <button class="more-options_btn">
          <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#3-points"
             (click)="showPopover(actionButtonsGroupMoreOptionsPopover)"
             #popoverContainerElement></i>

          <dx-popover class="rent-schedule-preview-popover"
                      [target]="popoverContainerElement"
                      [position]="{of: popoverContainerElement, my: 'right top', at: 'right bottom', collision: 'none'}"
                      #actionButtonsGroupMoreOptionsPopover>
            <div class="more-options_btn-group">
              <button (click)="addProjectNotes($event, item)">Add Comment</button>
              <button (click)="manageTeam($event, item)">Manage Team</button>
              <button (click)="goToProjectSchedule(item)">Project Schedule</button>
              <button (click)="goToLeaseAnalysis(item)">Lease Analysis</button>
            </div>

          </dx-popover>
        </button>
      </ng-container>
    </div>


    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
    <div *dxTemplate="let briefDealSummary of 'detail'" class="master-detail-block">
      <app-brief-deal-summary [leaseId]="briefDealSummary.data.leaseId"></app-brief-deal-summary>
    </div>
    <!-- End: Team -->
  </dx-data-grid>

  <div class="files-block" *ngIf="attachmentsVisible">
    <app-attachments [mode]="documentAttachmentPlaces.Building" [entityId]="id"
                     [files]="building.buildingFiles">
    </app-attachments>
  </div>

  <div class="position-relative"
       *ngIf="suites?.length > 0 && tenantsVisible">

    <dx-data-grid class="suites-grid"
                  allowHtmlText="true"
                  wordWrapEnabled="true"
                  [(visible)]="tenantsVisible"
                  [dataSource]="suites"
                  [showBorders]="false"
                  [columnAutoWidth]="true"
                  (onRowPrepared)="isInLease($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
                  *ngIf="suites?.length > 0">

      <dxo-search-panel width="100%"
                        [visible]="true"
                        [highlightCaseSensitive]="false">
      </dxo-search-panel>

      <dxo-paging [pageSize]="4">
      </dxo-paging>

      <dxo-pager [showPageSizeSelector]="true"
                 [showInfo]="true">
      </dxo-pager>

      <!-- Start: Status -->
      <dxi-column caption="Status"
                  cellTemplate="statusCellTemplate">
      </dxi-column>
      <div class="cell-status"
           *dxTemplate="let item of 'statusCellTemplate'">
        <ng-container *ngIf="getSuiteStatus(item?.data) as suiteStatus;">
          <div class="cell-status-text"
               [class]="suiteStatus.toLowerCase()">
            {{ suiteStatus }}
          </div>
        </ng-container>
      </div>
      <!-- End: Status -->

      <!-- Start: Tenant Contact -->
      <dxi-column caption="Contact"
                  headerCellTemplate="tenantHeaderCellTemplate"
                  cellTemplate="tenantCellTemplate"
                  [allowFiltering]="false"
                  [allowHeaderFiltering]="false"
                  [allowGrouping]="false"
                  [allowSearch]="true"
                  [allowSorting]="false">
      </dxi-column>
      <div *dxTemplate="let item of 'tenantHeaderCellTemplate'">
        Tenant(s)
      </div>
      <div class="cell-contact"
           *dxTemplate="let item of 'tenantCellTemplate'">
        <ng-container *ngIf="item?.data?.tenantCompany as tenantCompany; else noTenant">
          <div class="d-flex align-items-end cell-contact-name tenant-company-avatar__group">
            <div class="position-relative d-flex flex-column">
              <img
                *ngIf="item.data?.tenantTeamMembers.length && getAvatarUrl(item.data?.tenantTeamMembers[0]) as avatar"
                class="cell-contact-avatar"
                [src]="avatar?.url || 'assets/img/user.png'"/>
              <img *ngIf="tenantCompany?.logo?.url as url" [src]="url"
                   class="cell-contact-logo"/>
            </div>
            <div class="d-flex flex-column">
              <p *ngIf="item.data?.tenantTeamMembers.length">
                {{ item.data.tenantTeamMembers[0].fullName }}
              </p>
              <span>
                <a [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                   #tenantCompanyUrl>
                  {{ tenantCompany?.name }}
                </a>
                <!--                <dx-tooltip position="right" showEvent="dxhoverstart" hideEvent="dxhoverend"-->
                <!--                            [target]="tenantCompanyUrl">-->
                <!--                  Tenant Info-->
                <!--                </dx-tooltip>-->
              </span>
            </div>
          </div>
        </ng-container>
        <ng-template #noTenant>
          N/A
        </ng-template>
      </div>
      <!-- End: Tenant Contact -->
      <!-- Start: Unit -->
      <dxi-column alignment="left"
                  caption="Unit"
                  allowSearch="true"
                  cellTemplate="nameCellTemplate"
                  dataField="buildingUnit.name"
                  [encodeHtml]="false">
      </dxi-column>
      <div class="cell-suite"
           *dxTemplate="let item of 'nameCellTemplate'">
        <ng-container *ngIf="item?.data?.buildingUnit as buildingUnit">
          <div class="cell-suite-content">
            <span>{{ buildingUnit.name }}</span>
          </div>
        </ng-container>
      </div>
      <!-- End: Unit -->
      <!-- Start: Size -->
      <dxi-column caption="Size"
                  dataType="number"
                  alignment="left"
                  cellTemplate="totalAreaOccupiedTemplate">
      </dxi-column>
      <div class="cell-size"
           *dxTemplate="let item of 'totalAreaOccupiedTemplate'">
        <span class="cell-size_text" *ngIf="item?.data?.buildingUnit?.size as buildingUnitSize; else noSize">
          {{ buildingUnitSize | safeNumber }} SF
        </span>
        <ng-template #noSize>
          <span>
            N/A
          </span>
        </ng-template>
      </div>
      <!-- End: Size -->
      <!-- Start: Ending Base Rate (Annual Base Rent) -->
      <dxi-column caption="Current Rental Rate"
                  dataType="number"
                  alignment="left"
                  headerCellTemplate="baseRateHeaderCellTemplate"
                  cellTemplate="baseRateCellTemplate"
                  [editorOptions]="{ placeholder: 'Enter Rate'}">
      </dxi-column>
      <div *dxTemplate="let item of 'baseRateHeaderCellTemplate'">
        Ending Base Rate
      </div>
      <div *dxTemplate="let item of 'baseRateCellTemplate'" class="cell-base-rate">
        <ng-container *ngIf="item?.data?.endingBaseRate || item?.data?.annualBaseRent; else noBaseRent">
          <ng-container *ngIf="item?.data?.endingBaseRate as endingBaseRate">
            <div class="d-flex align-items-center">
              <a role="button">
                {{ endingBaseRate | safeCurrency }} {{ getUnitMetricsDisplayValue(item?.data) }}
              </a>
              <div class="cell-base-rate-tooltip">
                <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#eye-tooltip-12"
                   (mouseenter)="showPopover(rentPreviewSchedulePopover)"
                   #popoverContainerElement>
                </i>
              </div>
            </div>
            <dx-popover class="rent-schedule-preview-popover"
                        [target]="popoverContainerElement"
                        [position]="{of: popoverContainerElement, my: 'right top', at: 'right bottom', collision: 'none'}"
                        #rentPreviewSchedulePopover>
              <div *dxTemplate="let data of 'content'">
                <div class="tooltip-menu">
                  <div class="tooltip-header">
                    <div class="tooltip-header__title">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10 4.26099C13.7379 4.26099 16.8477 6.9507 17.5 10.5C16.8484 14.0493 13.7379 16.739 10 16.739C6.26213 16.739 3.15232 14.0493 2.5 10.5C3.15163 6.9507 6.26213 4.26099 10 4.26099ZM10 15.3526C11.4138 15.3523 12.7856 14.8721 13.891 13.9905C14.9963 13.109 15.7696 11.8783 16.0844 10.5C15.7685 9.12278 14.9946 7.89349 13.8894 7.01311C12.7842 6.13273 11.413 5.65335 10 5.65335C8.587 5.65335 7.21579 6.13273 6.11058 7.01311C5.00536 7.89349 4.23151 9.12278 3.91557 10.5C4.23036 11.8783 5.0037 13.109 6.10903 13.9905C7.21435 14.8721 8.58619 15.3523 10 15.3526V15.3526ZM10 13.6195C9.17265 13.6195 8.37919 13.2909 7.79417 12.7058C7.20915 12.1208 6.88049 11.3274 6.88049 10.5C6.88049 9.67266 7.20915 8.8792 7.79417 8.29418C8.37919 7.70916 9.17265 7.3805 10 7.3805C10.8273 7.3805 11.6208 7.70916 12.2058 8.29418C12.7908 8.8792 13.1195 9.67266 13.1195 10.5C13.1195 11.3274 12.7908 12.1208 12.2058 12.7058C11.6208 13.2909 10.8273 13.6195 10 13.6195ZM10 12.2331C10.4596 12.2331 10.9004 12.0505 11.2255 11.7255C11.5505 11.4005 11.7331 10.9596 11.7331 10.5C11.7331 10.0404 11.5505 9.59956 11.2255 9.27455C10.9004 8.94954 10.4596 8.76695 10 8.76695C9.54036 8.76695 9.09955 8.94954 8.77454 9.27455C8.44953 9.59956 8.26694 10.0404 8.26694 10.5C8.26694 10.9596 8.44953 11.4005 8.77454 11.7255C9.09955 12.0505 9.54036 12.2331 10 12.2331Z"
                          fill="#424B5A"/>
                      </svg>

                      <span>Rent Schedule Preview</span>
                    </div>

                    <button type="button"
                            (click)="rentPreviewSchedulePopover.visible = false;">

                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H18C22.4183 0.5 26 4.08172 26 8.5V18.5C26 22.9183 22.4183 26.5 18 26.5H8C3.58172 26.5 0 22.9183 0 18.5V8.5Z"
                          fill="#E8EAEE"/>
                        <path
                          d="M13 12.3891L16.8891 8.5L18 9.61094L14.111 13.5L18 17.3891L16.8891 18.5L13 14.611L9.11094 18.5L8 17.3891L11.8891 13.5L8 9.61094L9.11094 8.5L13 12.3891Z"
                          fill="#686F7B"/>
                      </svg>

                    </button>
                  </div>

                  <div class="recent-schedule-preview">
                    <div #baseRentalScheduleWrapperElement>
                      <ng-container *ngIf="item?.data?.baseRentalSchedule as baseRentalSchedule; else elseBlock">
                        <table>
                          <tr>
                            <th>Start</th>
                            <th>End</th>
                            <th>Base Rent {{ getUnitMetricsDisplayValue(item?.data) }}</th>
                            <th>Monthly Rent</th>
                            <th>Annual Rent</th>
                          </tr>
                          <tr *ngFor="let row of baseRentalSchedule.rows"
                              [class.current-row]="isCurrentBaseRentalRateSchedulePeriod(row)">
                            <td>
                              <div class="d-flex flex-column align-items-start">
                                <div class="current"
                                     *ngIf="isCurrentBaseRentalRateSchedulePeriod(row)">
                                  Current
                                </div>
                                <span>{{ row.start | date:'MM/dd/yyyy' }}</span>
                              </div>
                            </td>
                            <td>{{ row.end | date:'MM/dd/yyyy' }}</td>
                            <td>{{ row.baseRent | currency }}</td>
                            <td>
                              <ng-container *ngIf="row.monthlyRent; else noRent">
                                {{ row.monthlyRent | currency }}
                              </ng-container>
                              <ng-template #noRent>
                                -
                              </ng-template>
                            </td>
                            <td>
                              <ng-container *ngIf="row.annualRent; else noAnnualRent">
                                {{ row.annualRent | currency }}
                              </ng-container>
                              <ng-template #noAnnualRent>
                                -
                              </ng-template>
                            </td>
                          </tr>
                        </table>
                      </ng-container>

                      <ng-template #elseBlock>
                        <div class="comment">
                          No Base Rental Schedule available
                        </div>
                      </ng-template>
                    </div>

                  </div>
                </div>
              </div>
            </dx-popover>
          </ng-container>
          <ng-container *ngIf="item?.data?.endingAnnualBaseRate as endingAnnualBaseRate">
            <div>
              ({{ endingAnnualBaseRate | safeCurrency }})
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noBaseRent>
          N/A
        </ng-template>
      </div>
      <!-- End: Ending Base Rate (Annual Base Rent) -->
      <!-- Start: Lease Start -->
      <dxi-column caption="Lease Start"
                  dataType="date"
                  alignment="left"
                  cellTemplate="leaseStartCellTemplate">
      </dxi-column>
      <div class="cell-lease-start"
           *dxTemplate="let item of 'leaseStartCellTemplate'">
        <div *ngIf="item?.data?.leaseStartDate as leaseStartDate; else noLeaseStartDate">
          <p>
            {{ leaseStartDate | safeDate: 'MM/dd/yyyy' }}
          </p>
          <span inlineSVG="assets/img/sign-icons.svg#calendar-icon"
                #startDateInfo>
          </span>
          <dx-tooltip class="active-hover"
                      position="bottom"
                      showEvent="dxhoverstart"
                      [hideEvent]="{name: 'dxhoverend', delay: 1000}"
                      [target]="startDateInfo">
            <div class="date-tooltip">
              <p class="option-notice__title">Option Notice Dates:</p>
              <div class="d-flex flex-column date-tooltip-wrapper">
                <div>
                  <p class="date-tooltip-title">Renewal</p>
                  <p class="date-tooltip-text">
                    <ng-container *ngIf="item?.data?.renewalNoticeDate as renewalDate; else noRenewalDate">
                      {{ renewalDate | safeDate: 'MM/dd/yyyy' }}
                    </ng-container>
                    <ng-template #noRenewalDate>
                      N/A
                    </ng-template>
                  </p>
                  <a class="date-tooltip-icon"
                     [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                     [queryParams]="{target: 'noticeDates'}">
                    <span inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></span>
                  </a>
                </div>
                <div>
                  <p class="date-tooltip-title">Expansion</p>
                  <p class="date-tooltip-text">
                    <ng-container *ngIf="item?.data?.expansionNoticeDate as expansionDate; else noExpansionDate">
                      {{ expansionDate | safeDate: 'MM/dd/yyyy' }}
                    </ng-container>
                    <ng-template #noExpansionDate>
                      N/A
                    </ng-template>
                  </p>
                  <a class="date-tooltip-icon"
                     [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                     [queryParams]="{target: 'noticeDates'}">
                    <span inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></span>
                  </a>
                </div>
                <div>
                  <p class="date-tooltip-title">Termination</p>
                  <p class="date-tooltip-text">
                    <ng-container *ngIf="item?.data?.terminationNoticeDate as terminationDate; else noTerminationDate">
                      {{ terminationDate | safeDate: 'MM/dd/yyyy' }}
                    </ng-container>
                    <ng-template #noTerminationDate>
                      N/A
                    </ng-template>
                  </p>
                  <a class="date-tooltip-icon"
                     [routerLink]="item?.data?.lease?.id ? ['../../person-desktop/' + item?.data?.lease?.id] : null"
                     [queryParams]="{target: 'noticeDates'}">
                    <span inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></span>
                  </a>
                </div>
              </div>
            </div>
          </dx-tooltip>
        </div>
        <ng-template #noLeaseStartDate>
          N/A
        </ng-template>
      </div>
      <!-- End: Lease Start -->
      <!-- Start: Lease End -->
      <dxi-column caption="Lease End"
                  dataType="date"
                  alignment="left"
                  cellTemplate="leaseEndCellTemplate">
      </dxi-column>
      <div class="cell-lease-end"
           *dxTemplate="let item of 'leaseEndCellTemplate'">
        <ng-container *ngIf="item?.data?.leaseEndDate as leaseEndDate; else noLeaseEndDate">
          <div class="position-relative">
            <p class="m-0">{{ leaseEndDate | safeDate: 'MM/dd/yyyy' }}</p>
            <div class="position-absolute d-flex justify-content-between align-items-center date-indicator"
                 [class]="getLeaseExpirationDate(leaseEndDate)"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </ng-container>
        <ng-template #noLeaseEndDate>
          N/A
        </ng-template>
      </div>
      <!-- End: Lease End -->
      <!-- Start: List Status -->
      <dxi-column cellTemplate="listStatusCellTemplate">
      </dxi-column>
      <div class="cell-list-status d-flex align-items-center"
           *dxTemplate="let item of 'listStatusCellTemplate'">
        <ng-container class="list-status"
                      *ngIf="item?.data.buildingUnit.listing; then listedTemplate; else notListed">
        </ng-container>
        <ng-template #listedTemplate>
          <div class="cell-status-listed-wrapper">
            <button type="button" class="cell-status-btn listed unit-listed">
              <i inlineSVG="assets/img/sign-icons.svg#check-mark-icon"></i>
              <span>Unit Listed</span>
            </button>
            <div class="listed-actions">
              <button type="button" class="cell-status-btn listed"
                      (click)="listSpace(listSpaceButton, item?.data)"
                      #listSpaceButton #editTooltip>
                <i inlineSVG="assets/img/sign-icons.svg#edit-icon"></i>
                <span>Edit</span>
              </button>
              <ng-container *ngIf="item?.data.buildingUnit.listing">
                <button type="button" class="cell-status-btn listed"
                        (click)="viewListing(item?.data)"
                        #viewTooltip>
                  <i inlineSVG="assets/img/sign-icons.svg#eye-icon"></i>
                  <span>View</span>
                </button>
                <dx-tooltip class="unit-action-tooltip"
                            position="left"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            [target]="viewTooltip">
                  <span>View</span>
                </dx-tooltip>
              </ng-container>
              <dx-tooltip class="unit-action-tooltip"
                          position="left"
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          [target]="editTooltip">
                <span>Edit</span>
              </dx-tooltip>
            </div>
          </div>
        </ng-template>
        <ng-template #notListed>
          <div class="cell-status-off-wrapper">
            <button type="button" class="cell-status-btn off-market">
              <i inlineSVG="assets/img/sign-icons.svg#close-icon"></i>
              <span>Off-Market</span>
            </button>
            <button type="button" class="cell-status-btn off-list-space"
                    (click)="listSpace(listSpaceButton, item?.data)"
                    #listSpaceButton #listSpaceTooltip>
              <i inlineSVG="assets/img/sign-icons.svg#plus-icon"></i>
              <span>List Space</span>
            </button>
            <dx-tooltip class="unit-action-tooltip"
                        position="left"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        [target]="listSpaceTooltip">
              <span>List Space</span>
            </dx-tooltip>
          </div>
        </ng-template>
        <ng-container *ngIf="canInitiateDeal(item?.data)">
          <ng-container *appIsFeatureEnabled="Feature.ColaboFeature">
            <button type="button" class="cell-status-btn initiate-deal"
                    (click)="initiateDeal(initiateDealButton, item?.data)"
                    #initiateDealButton>
              <i inlineSVG="assets/img/sign-icons.svg#arrow-right-icon"></i>
              <span>Initiate deal</span>
            </button>
            <dx-tooltip position="top" maxWidth="250"
                        [target]="initiateDealButton"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend">
              <div class="initiateDealButton_tooltip" *dxTemplate="let data = data; of: 'content'">
                {{ getInitiateDealTooltip(item?.data) }}
              </div>
            </dx-tooltip>
          </ng-container>
        </ng-container>
      </div>
      <!-- End: List Status -->
    </dx-data-grid>

    <div class="position-absolute lease-expiration">
      <p>Lease expiration:</p>
      <div>
        <i inlineSVG="assets/img/sign-icons.svg#circle-icon" class="green-circle"></i>
        <span>>24 month</span>
      </div>
      <div>
        <i inlineSVG="assets/img/sign-icons.svg#circle-icon" class="blue-circle"></i>
        <span>24-12 months</span>
      </div>
      <div>
        <i inlineSVG="assets/img/sign-icons.svg#circle-icon" class="yellow-circle"></i>
        <span>6-12 months</span>
      </div>
      <div>
        <i inlineSVG="assets/img/sign-icons.svg#circle-icon" class="red-circle"></i>
        <span><6 months</span>
      </div>
      <div>
        <i inlineSVG="assets/img/sign-icons.svg#circle-icon" class="grey-circle"></i>
        <span>no lease</span>
      </div>
    </div>
  </div>

  <div class="no-data-placeholder"
       *ngIf="tenantsVisible && suites?.length < 1">
    <img src="/assets/img/no-data.svg" width="24" height="24" alt="No data icon"/>
    <p class="no-data-placeholder__title">No unit data :(</p>
    <p class="no-data-placeholder__message">Please wait for Statera admins to upload data to<br/>this building</p>
  </div>

  <div class="no-data-placeholder"
       *ngIf="dealsGridVisible && deals?.length < 1">
    <img src="/assets/img/no-data.svg" width="24" height="24" alt="No data icon"/>
    <p class="no-data-placeholder__title">No deal data :(</p>
    <p class="no-data-placeholder__message">You have no active negotiations. Start a new deal<br/>or a renewal process
      to see something here</p>
    <button type="button" (click)="goToUnits()">GO TO UNITS</button>
  </div>

  <ng-container *ngIf="budgetVisible">
    <app-building-budget [building]="building" [buildingUnits]="buildingUnits"></app-building-budget>
  </ng-container>
</div>
