<ng-container *ngIf="limitAvatarList(avatarList, 5) as limitedAvatarList">
    <div class="avatars">
        <div *ngFor="let avatarItem of limitedAvatarList; let index = index"
             [attr.class]="'avatars-item avatars-item-'+ (index + 1)">
            <div *ngIf="avatarItem" #avatarElement>
                <img *ngIf="avatarItem?.url" class="object-fit-cover rounded-circle avatars-item-image"
                     [src]="avatarItem?.url || 'assets/img/user.png'" [alt]="avatarItem.fullName"/>

                <div *ngIf="!avatarItem?.url && avatarItem" class="avatars-item-initials">
                    {{ getInitials(avatarItem) }}
                </div>
                <dx-tooltip class="v2-default-tooltip"
                            position="left"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            contentTemplate="content"
                            [target]="avatarElement">
                  <div>
                    <p class="mb-0">
                      {{ avatarItem.fullName }}
                    </p>
                    <p class="mb-0">
                      {{ avatarItem.companyName }}
                    </p>
                  </div>

                </dx-tooltip>
            </div>
        </div>

        <div class="avatars-item avatars-item-6"
             *ngIf="avatarList && 0 < avatarList.length - limitedAvatarList.length">
            <div class="rounded-circle avatars-item-image">
                <ng-container *ngIf="avatarList.length - limitedAvatarList.length as countOfLimitedTeamMembers">
                    <ng-container *ngIf="9 > countOfLimitedTeamMembers; else moreTeamMembersTpl">
                        +{{ countOfLimitedTeamMembers }}
                    </ng-container>
                    <ng-template #moreTeamMembersTpl>
                        9+
                    </ng-template>
                </ng-container>
            </div>
        </div>

        <span class="chat-info" *ngIf="context == 'chat-external'"><strong>Everyone</strong> can see this chat</span>
        <span class="chat-info" *ngIf="context == 'chat-internal'"><strong>Your team</strong> can see this chat</span>
    </div>
</ng-container>

