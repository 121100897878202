<div class="proposals-grid">
  <ng-container *ngIf="(briefDealSummaries | async) as data">

    <div *ngIf="data.length; else noDataTemplate" >
      <table class="proposals-grid-table">
        <thead>
        <tr>
          <th width="240px">Stage</th>
          <th>Start Date</th>
          <th>Term</th>
          <th>Proposed SF</th>
          <th width="240px">Rental Rate</th>
          <th>Free Rent</th>
          <th>Tenant Improvements</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of data">
          <!-- Stage Column -->
          <td>
            <div class="d-flex flex-column">
              <span class="stage-title">{{ item?.projectTemplateItemName }}</span>
              <div class="stage-line-wrapper">
                <div *ngFor="let stage of [].constructor(maxStageCount); let i = index"
                     [class.active-stage]="item?.stageNumber > i"
                     class="stage-line">
                </div>
              </div>
              <div class="stage-user" *ngIf="item?.userWhoProposed">
                Proposed By {{ item?.userWhoProposed?.role }}
              </div>
            </div>
          </td>

          <!-- Start Date Column -->
          <td>{{ item?.startDateOfDeal | date }}</td>

          <!-- Term Column -->
          <td>{{ getCurrentTerm(item) }}</td>

          <!-- Proposed SF Column -->
          <td>{{ getCurrentTenantSquareFootage(item) }}</td>

          <!-- Rental Rate Column -->
          <td class="rental-rate">{{ getCurrentRentalRate(item) }}</td>

          <!-- Free Rent Column -->
          <td>{{ getCurrentFreeRent(item) }}</td>

          <!-- Tenant Improvements Column -->
          <td>{{ getCurrentTenantImprovements(item) }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Template for No Data -->
    <ng-template #noDataTemplate>
      <div class="no-data-template">
        <img src="assets/img/no-data-list.svg" alt="no-data-list">
        <span>No Data Yet</span>
        <p>Please check back once negotiations are underway</p>
      </div>
    </ng-template>
    <!-- Template for No Data End -->


  </ng-container>



</div>
